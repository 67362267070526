import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { SwitchState } from './types';

export const ReservationBoxStepSwitcher = (
    {
        isDisableNextStep,
        onSwitch,
        switchState,
    }: {
        isDisableNextStep: boolean;
        onSwitch: (switchState: SwitchState) => void;
        switchState: SwitchState;
    }) => {
    const { t } = useTranslation('restaurant.reservation');

    const sxSwitch1 = {
        '&:hover': {
            cursor: switchState === 'reservation' ? 'cursor' : 'pointer',
        },
        backgroundColor: switchState === 'reservation' ? 'primary.main' : 'background.default',
        borderRadius: '10px',
        height: '12px',
        mr: '0.5rem',
        width: '12px',
    };

    const sxSwitch2 = {
        '&:hover': {
            cursor: switchState === 'personalIdentification' || isDisableNextStep ? 'cursor' : 'pointer',
        },
        backgroundColor: switchState === 'personalIdentification' ? 'primary.main' : 'background.default',
        borderRadius: '10px',
        height: '12px',
        mr: '0.5rem',
        width: '12px',
    };

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: '36px' }}>
            {switchState === 'reservation' && (<Typography variant="h1">{t('reservation')}</Typography>)}
            {switchState === 'personalIdentification' && (<Typography variant="h1">{t('personal data')}</Typography>)}

            <Box sx={{ display: 'flex' }}>
                <Box onClick={() => onSwitch('reservation')} sx={sxSwitch1} />
                <Box
                    onClick={() => isDisableNextStep ? null : onSwitch('personalIdentification')}
                    sx={sxSwitch2}
                />
            </Box>
        </Box>
    );
};
