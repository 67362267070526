import {
    useMutation,
} from '@tanstack/react-query';
import { z } from 'zod';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { parsePathAndMethod } from 'common/utils/typedApi';
import { logger } from 'common/utils/logger';
import { getUrlWithParamsAndQueries } from 'common/utils/getUrlWithParamsAndQueries';
import { createInlineError } from 'src/shared/errors';
import { useAppContext } from 'src/appState';
import { ApiClientRequestCanceledError, ApiClientRequestFailedError, doQerkoRequest } from 'src/apiClient';

import type {
    UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';

export const useQrMutation = <
    Path extends keyof APIv1Qr['ENDPOINT'],
    Result = APIv1Qr['ENDPOINT'][Path]['result'],
    Input = APIv1Qr['ENDPOINT'][Path]['input'],
>(
    fullPath: Path,
    options: UseMutationOptions<Result, unknown, Input, unknown> = {},
): UseMutationResult<Result | undefined, unknown, Input, unknown> => {
    const { apiUrl, auth } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('api.client');
    const { method, path } = parsePathAndMethod(fullPath);

    return useMutation<Result | undefined, unknown, Input>(
        {
            // @ts-expect-error
            mutationFn: async ({ data: body, params, query, headers: inputHeaders }: Input, headers?: Record<string, string>, lang?: string) => {
                const resolvedPath: string = getUrlWithParamsAndQueries(path.toString(), { params, query });
                const result = await doQerkoRequest(auth, null, apiUrl, () => `/api/v1/qr${resolvedPath}`, {
                    body,
                    headers: {
                        ...(headers ?? {}),
                        ...(inputHeaders ?? {}),
                    },
                    lang,
                    method,
                    validator: z.any(),
                });

                if (result.isErr()) {
                    if (result.error instanceof ApiClientRequestCanceledError) {
                        // nothing
                    } else if (result.error instanceof ApiClientRequestFailedError ) {
                        enqueueSnackbar(t('error.message.connectivity'), { variant: 'error' });
                    } else if (result.error.response.statusCode === 413) {
                        enqueueSnackbar(t('warning.message.tooLarge'), { variant: 'warning' });
                    } else {
                        logger.error(result.error);
                        enqueueSnackbar(t('error.message.unexpected'), { variant: 'error' });
                    }

                    return;
                }

                // log validation error
                if (result.value.statusCode === 400 && ['scope', 'query', 'params', 'data', 'result'].includes(result.value.body.type ?? '')) {
                    logger.error(createInlineError('ApiClient', 'Request validation error', { extra: {
                        response: result.value.body,
                        statusCode: result.value.statusCode,
                        url: `/api/v1/restaurant-admin${resolvedPath}`,
                    } }));
                }

                // we want to show message from server !
                if (result.value.statusCode >= 300) {
                    // Default message is for unexpected returns. For example from load balancer etc...
                    enqueueSnackbar(result.value.body.message ?? t('error.message.unexpected'), { variant: 'error' });
                    return;
                }

                return {
                    httpStatus: result.value.statusCode,
                    result: result.value.body,
                };
            },
            ...options,
        },
    );
};

