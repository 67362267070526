import { isLoggerStringifyable, toLoggerString, type LoggerStringifyable } from './loggerStringifyable';

export const createInlineError = (
    name: string,
    message: string,
    props: {
        previousError?: Error;
        extra?: QerkoErrorExtraValue;
        errorConstructorOpt?: () => unknown;
    } = {},
): QerkoError => {
    const self = new (class extends QerkoError {
        protected __prop: any;
    })(message, props.previousError, { extra: props.extra });
    self.name = name;

    if (!Error.captureStackTrace) {
        self.stack = (new Error).stack;
    } else {
        Error.captureStackTrace(self, props.errorConstructorOpt ?? createInlineError);
    }

    return self;
};

export const createInlineErrorFrom = (
    error: Error,
    name?: string,
): QerkoError => {
    const self = new (class extends QerkoError {
        protected __prop: any;
    })(error.message, error);

    if (!Error.captureStackTrace) {
        self.stack = (new Error).stack;
    } else {
        Error.captureStackTrace(self, createInlineErrorFrom);
    }

    if (name !== undefined) {
        self.name = name;
    }

    return self;
};

export interface QerkoErrorExtraValue {
    [index: string]: string | number | boolean | null | undefined | (string | number | boolean | null | undefined)[] | QerkoErrorExtraValue[] | QerkoErrorExtraValue;
}

export abstract class QerkoError extends Error implements LoggerStringifyable {

    public readonly previousError?: Error;

    private readonly defaultExtra: QerkoErrorExtraValue = {};

    protected abstract __prop: any;

    public constructor(
        message: string,
        previousError?: Error,
        _props: {
            extra?: QerkoErrorExtraValue;
        } = {},
    ) {
        super(message);
        this.name = this.constructor.name || 'QerkoError'; // hack for SWC
        this.previousError = previousError;
        this.defaultExtra = _props.extra ?? {};
    }

    public getExtra(): QerkoErrorExtraValue {
        let previousExtra = { ...this.defaultExtra };
        if (this.previousError !== undefined && this.previousError instanceof QerkoError) {
            previousExtra = { previousExtra: this.previousError.getExtra() };
        }

        return {
            ...previousExtra,
        };
    }

    public [toLoggerString](): string {
        let loggerString = (this.stack ?? `${this.name}: ${this.message}`).trim();

        const previousError = this.previousError;
        if (previousError !== undefined) {
            const previousLoggerString = (
                (isLoggerStringifyable(previousError)) ?
                    previousError[toLoggerString]() :
                    (previousError.stack ?? `${previousError.name}: ${previousError.message}`).trim()
            ).trim();
            if (previousLoggerString) loggerString = loggerString + '\n \n  Previous error:\n \n' + (previousLoggerString.replace(/^/mg, '    '));
        }

        return loggerString.trim();
    }
}
