import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { keyframes } from '@emotion/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GreenCheckIcon } from 'common/components/icons/GreenCheck';
import { useState } from 'react';
import Button from '@mui/material/Button';

import { ReservationBoxLabelValue } from './ReservationBoxLabelValue';
import { parseSlotIdToDate } from './util';
import { ReservationBoxWhiteWrap } from './ReservationBoxWhiteWrap';
import { ReservationBoxDetailRejectDialog } from './ReservationBoxDetailRejectDialog';

import type { Theme, SxProps } from '@mui/material';
import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';

const getAnimation = keyframes`from { opacity: 0; }`;

export const ReservationBoxDetail = (
    {
        reservation,
    }: {
        reservation: APIv1Qr['ENDPOINT']['GET:/reservation/retrieve']['result']['result']['reservation'];
    }
) => {
    const { t } = useTranslation('restaurant.reservation');
    const [isRejectDialogOpen, setRejectDialogOpen] = useState(false);
    const mainBoxMargin = '40px';
    let sxStatusValue: SxProps<Theme> = { color: 'black' };

    if (reservation.status === 'waiting') sxStatusValue = { animation: `${getAnimation} 1s infinite alternate`, color: '#FF8C00' };
    if (reservation.status === 'confirmed') sxStatusValue.color = 'primary.main';
    if (reservation.status === 'rejected') sxStatusValue.color = 'colors.red.main';

    return (
        <ReservationBoxWhiteWrap>
            <>
                <ReservationBoxDetailRejectDialog isOpen={isRejectDialogOpen} reservation={reservation} setRejectDialogOpen={setRejectDialogOpen} />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <Box sx={{ mt: mainBoxMargin, mx: mainBoxMargin }}>
                        <Box sx={{ alignItems: 'center', backgroundColor: '#D6F3EC', borderRadius: '8px', display: 'flex', height: '72px', justifyContent: 'center', mb: '24px', width: '72px' }}>
                            <GreenCheckIcon />
                        </Box>
                        <Typography sx={{ mb: '8px' }} variant="h1">{t('header.reservationSuccess')}</Typography>
                        <Typography paragraph sx={{ mb: '24px' }}>{t('reservationConfirmation')} {reservation.user.email}.</Typography>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mb: '2rem' }}>
                            {reservation.reservationTime && (<ReservationBoxLabelValue label={t('dateAndTime')} value={parseSlotIdToDate(reservation.reservationTime)} />)}
                            <ReservationBoxLabelValue label={t('numberOfPeople')} value={`${reservation.seats}`} />
                            <ReservationBoxLabelValue label={t('underTheName')} value={`${reservation.user.name}`} />
                            <ReservationBoxLabelValue
                                label={t('status')}
                                sxValue={sxStatusValue}
                                value={t(`state.${reservation.status}`)}
                            />

                            {(reservation.status === 'waiting' || reservation.status === 'confirmed') && (
                                <Button
                                    color="error"
                                    onClick={() => setRejectDialogOpen(true)}
                                    size="small"
                                    sx={{
                                        mt: '1rem',
                                        p: 1,
                                    }}
                                    variant="contained"
                                >
                                    {t('button.rejectReservation')}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </>
        </ReservationBoxWhiteWrap>
    );
};
