import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { QerkoLogoIcon } from 'common/components/icons/QerkoLogoIcon';
import { SeznamLogoIcon } from 'common/components/icons/SeznamLogoIcon';

export const ReservationBoxFooter = (
    {
        includeSeznam,
    }: {
        includeSeznam: boolean;
    }) => {
    const { t } = useTranslation('restaurant.reservation');

    return (
        <>
            <Box sx={{
                alignItems: 'center',
                borderTop: '1px solid #E9EDEC',
                display: 'flex',
                height: '55px',
                justifyContent: 'center',
            }}
            >
                <Typography component="span" sx={{ color: 'colors.grey.main', fontSize: '0.8rem' }}>{t('reservation over')}</Typography>
                <QerkoLogoIcon sx={{ height: '17px', ml: '0.4rem', width: '58px' }} />
                {
                    includeSeznam && (
                        <>
                            <SeznamLogoIcon sx={{ height: '40px', ml: '0.4rem', width: '150px' }} />
                        </>
                    )
                }
            </Box>
        </>
    );
};

