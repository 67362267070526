import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useRouter } from 'next/router';
import { RedCrossIcon } from 'common/components/icons/RedCross';
import { OnClickButton } from 'common/components/LoadingButton';
import { useQrMutation } from 'restaurant/hooks/useQrMutation';

import type { Uuid } from 'src/shared/types';
import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';

export const ReservationBoxDetailRejectDialog = (
    {
        isOpen,
        setRejectDialogOpen,
        reservation,
    }: {
        isOpen: boolean;
        setRejectDialogOpen: (isOpen: boolean) => void;
        reservation: APIv1Qr['ENDPOINT']['GET:/reservation/retrieve']['result']['result']['reservation'];
    }
) => {
    const router = useRouter();
    const { mutateAsync: submit } = useQrMutation('POST:/reservation/cancel');

    const { t } = useTranslation('restaurant.reservation');

    const handleSubmitCancelReservation = async (reservationId: string) => {
        const response = await submit({ data: { reservationId: reservationId as Uuid } });
        if (response === undefined) {
            return;
        }

        if (response.httpStatus === 200) return router.reload();
    };

    return (
        <Dialog
            aria-labelledby={t('dialog.title.rejectReservation')}
            onClose={() => setRejectDialogOpen(false)}
            open={isOpen}
        >
            <DialogTitle sx={{ display: 'flex', p: '1rem 50px 1rem 1rem' }}>
                {t('dialog.title.rejectReservation')}
                <IconButton
                    aria-label="close"
                    onClick={() => setRejectDialogOpen(false)}
                    sx={{
                        color: 'grey',
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                >
                    <RedCrossIcon fill="grey" sx={{ width: '18px' }} />
                </IconButton>
            </DialogTitle>
            <DialogActions sx={{ p: '1rem' }}>
                <OnClickButton
                    buttonProps={{
                        color: 'error',
                        variant: 'contained',
                    }}
                    onClick={async () => {
                        await handleSubmitCancelReservation(reservation.id);
                        setRejectDialogOpen(false);
                    }}
                >
                    {t('button.rejectReservation')}
                </OnClickButton>
            </DialogActions>
        </Dialog>
    );
};
