import { UuidSchema } from 'src/shared/types';
import { useRaRouter } from 'restaurant-admin/hooks/useRaRouter';

import type { Uuid } from 'src/shared/types';

export const useOptionalSelectedRestaurantId = (): [boolean, null] | [true, Uuid] => {
    const router = useRaRouter();
    if (!router.isReady) {
        return [false, null] as const;
    }

    const selectedRestaurantIdResult = UuidSchema.safeParse(router.query.selectedRestaurantId);

    return [true, selectedRestaurantIdResult.success ? selectedRestaurantIdResult.data : null] as const;
};

export const selectedRestaurantIdError = Symbol('selectedRestaurantIdError');

export const useSelectedRestaurantId = (): Uuid => {
    const result = useOptionalSelectedRestaurantId();
    if (result[1] === null) {
        throw selectedRestaurantIdError;
    }

    return result[1];
};
