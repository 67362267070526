import { assertNotNull } from 'common/utils/assert';

import { ReservationBox } from './ReservationBox';

import type { z } from 'zod';
import type { RestaurantQrSchema } from 'src/shared/restaurantTypes';

export const ReservationQueryWrap = ({
    restaurant,
}: {
    restaurant: z.infer<typeof RestaurantQrSchema>['restaurant'];
}) => {
    assertNotNull(restaurant.id);

    return (
        <ReservationBox
            restaurantId={restaurant.id}
        />
    );
};
