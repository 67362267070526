import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { RestaurantMenuNameAndDate } from './RestaurantMenuNameAndDate';
import { RestaurantMenuItems } from './RestaurantMenuItems';

import type { APIv1RestaurantAdmin } from 'src/shared-interface/ApiRestaurantAdminInterface';

export const RestaurantMenuDaily = (
    {
        listOfDailyMenu,
    }: {
        listOfDailyMenu: APIv1RestaurantAdmin['ENDPOINT']['GET:/menu/menu-data-for-pdf']['result']['result']['dailyMenuData'];
    }) => {
    const { t } = useTranslation('common');

    return (
        <Box>
            {listOfDailyMenu.map((dailyMenu, index1) => {
                return (
                    <Box key={index1}>
                        <Typography component="h2" sx={{ textTransform: 'capitalize' }} variant="h4">
                            {t('date', { formatParams: { value: { weekday: 'long' } }, value: dailyMenu.date })}
                        </Typography>
                        <Typography component="span" sx={{ fontSize: '.80rem' }} variant="inherit">
                            ({t('date', { formatParams: { value: { day: 'numeric', month: 'long' } }, value: dailyMenu.date })})
                        </Typography>

                        {dailyMenu.data.map((menu, index2) => {
                            return (
                                <Box key={index2}>
                                    <RestaurantMenuNameAndDate
                                        description={menu?.description}
                                        name={menu?.name}
                                        openFrom={menu?.openFrom}
                                        openTo={menu?.openTo}
                                    />
                                    <RestaurantMenuItems permanentMenuItems={menu.items} />
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </Box>

    );
};
