import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import type { APIv1RestaurantAdmin } from 'src/shared-interface/ApiRestaurantAdminInterface';

export const RestaurantMenuItems = (
    {
        permanentMenuItems,
    }: {
        permanentMenuItems: APIv1RestaurantAdmin['ENDPOINT']['GET:/menu/menu-data-for-pdf']['result']['result']['permanentMenuData'][0]['items'];
    }) => {
    const { t, i18n } = useTranslation('common');

    return (
        <>
            {permanentMenuItems.map((item, index) => {
                if (item.toTable === false && item.toBill === false) return null;

                return (
                    <Box key={index} sx={{ mb: '1rem' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="inherit">{item.name}</Typography>
                                <Typography sx={{
                                    color: 'dimgrey',
                                    fontSize: '.80rem',
                                    mb: '0.5rem',
                                }} variant="inherit"
                                >
                                    {item.description}
                                </Typography>
                                <Typography variant="inherit">
                                    {t('price', {
                                        formatParams: {
                                            value: { currency: item.currency, locale: i18n.language },
                                        },
                                        value: item.price,
                                    })}
                                </Typography>
                            </Box>
                            {item.images[0] && ( <Avatar alt={item.name} src={`${item.images[0]}:resize/80x80.jpg`} sx={{ borderRadius: '8px', height: '80px', width: '80px' }} />
                            )}
                        </Box>
                    </Box>
                );
            })}
        </>
    );
};
