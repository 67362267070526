export enum PaymentGatewayId {
    GPE = 'GPE',
    BENEFIT_PLUS = 'BENEFIT_PLUS',
    CSOB = 'CSOB',
    CSOB_TEST = 'CSOB_TEST',
    CSOB_APPLE_PAY = 'CSOB_APPLE_PAY',
    CSOB_GOOGLE_PAY = 'CSOB_GOOGLE_PAY',
    CSOB_EDENRED = 'CSOB_EDENRED',
    CSOB_UP = 'CSOB_UP',
    CSOB_LIDL = 'CSOB_LIDL',
    CSOB_MALL_PAY = 'CSOB_MALL_PAY',
    SODEXO = 'SODEXO',
    STRIPE = 'STRIPE',
    STRIPE_APPLE_PAY = 'STRIPE_APPLE_PAY',
    STRIPE_GOOGLE_PAY = 'STRIPE_GOOGLE_PAY',
    STRIPE_WEB = 'STRIPE_WEB',
    ADYEN = 'ADYEN',
    ADYEN_APPLE_PAY = 'ADYEN_APPLE_PAY',
    ADYEN_GOOGLE_PAY = 'ADYEN_GOOGLE_PAY',
    ADYEN_WEB = 'ADYEN_WEB',
    ADYEN_PAYMENT_LINK = 'ADYEN_PAYMENT_LINK',
    PAYU_APPLE_PAY = 'PAYU_APPLE_PAY',
    PAYU_GOOGLE_PAY = 'PAYU_GOOGLE_PAY',
    PAYU = 'PAYU',
    PAYU_WEB = 'PAYU_WEB',
    TWISTO = 'TWISTO',
    TWISTO_WEB = 'TWISTO_WEB',
    UPSK_FOOD_CARD = 'UPSK_FOOD_CARD',
    CONFIRMO_BTC = 'CONFIRMO_BTC',
    FINBRICKS = 'FINBRICKS',
    FAKE = 'FAKE',
}

export enum PaymentMethodType {
    CSOB_GOOGLE_PAY = 'CSOB_GOOGLE_PAY',
    APPLE_PAY = 'APPLE_PAY',
    BENEFIT_PLUS = 'BENEFIT_PLUS',
    MALL_PAY = 'MALL_PAY',
    CARD = 'CARD',
    EDENRED = 'EDENRED',
    CSOB_UP = 'CSOB_UP',
    CSOB_LIDL = 'CSOB_LIDL',
    SODEXO = 'SODEXO',
    STRIPE_CARD = 'STRIPE_CARD',
    STRIPE_APPLE_PAY = 'STRIPE_APPLE_PAY',
    STRIPE_GOOGLE_PAY = 'STRIPE_GOOGLE_PAY',
    STRIPE_WEB = 'STRIPE_WEB',
    ADYEN_CARD = 'ADYEN_CARD',
    ADYEN_APPLE_PAY = 'ADYEN_APPLE_PAY',
    ADYEN_GOOGLE_PAY = 'ADYEN_GOOGLE_PAY',
    ADYEN_WEB = 'ADYEN_WEB',
    ADYEN_PAYMENT_LINK = 'ADYEN_PAYMENT_LINK',
    PAYU_APPLE_PAY = 'PAYU_APPLE_PAY',
    PAYU_GOOGLE_PAY = 'PAYU_GOOGLE_PAY',
    PAYU_CARD = 'PAYU_CARD',
    PAYU_WEB = 'PAYU_WEB',
    TWISTO = 'TWISTO',
    TWISTO_WEB = 'TWISTO_WEB',
    UPSK_FOOD_CARD = 'UPSK_FOOD_CARD',
    BTC = 'BTC',
    FINBRICKS = 'FINBRICKS',
}

export enum CardPaymentMethod {
    MALL_PAY = 'MALL_PAY',
    CARD = 'CARD',
    EDENRED = 'EDENRED',
    CSOB_UP = 'CSOB_UP',
    CSOB_LIDL = 'CSOB_LIDL',
    SODEXO = 'SODEXO',
    STRIPE_CARD = 'STRIPE_CARD',
    PAYU_CARD = 'PAYU_CARD',
    ADYEN_CARD = 'ADYEN_CARD',
    TWISTO = 'TWISTO',
    UPSK_FOOD_CARD = 'UPSK_FOOD_CARD',
    BTC = 'BTC',
    FINBRICKS = 'FINBRICKS',
}

export enum WalletPaymentMethod {
    GOOGLE_PAY = 'GOOGLE_PAY',
    APPLE_PAY = 'APPLE_PAY',
    BENEFIT_PLUS = 'BENEFIT_PLUS',
    BTC = 'BTC', // @deprecated, btc is saved in Card table
}

export const paymentGatewayIdsByPaymentMethod: Record<PaymentMethodType, [PaymentGatewayId, ...PaymentGatewayId[]]> = {
    [PaymentMethodType.CSOB_GOOGLE_PAY]: [PaymentGatewayId.CSOB_GOOGLE_PAY],
    [PaymentMethodType.APPLE_PAY]: [PaymentGatewayId.CSOB_APPLE_PAY],
    [PaymentMethodType.BENEFIT_PLUS]: [PaymentGatewayId.BENEFIT_PLUS],
    [PaymentMethodType.MALL_PAY]: [PaymentGatewayId.CSOB_MALL_PAY],
    [PaymentMethodType.CARD]: [PaymentGatewayId.CSOB, PaymentGatewayId.GPE],
    [PaymentMethodType.EDENRED]: [PaymentGatewayId.CSOB_EDENRED],
    [PaymentMethodType.CSOB_UP]: [PaymentGatewayId.CSOB_UP],
    [PaymentMethodType.CSOB_LIDL]: [PaymentGatewayId.CSOB_LIDL],
    [PaymentMethodType.SODEXO]: [PaymentGatewayId.SODEXO],
    [PaymentMethodType.STRIPE_CARD]: [PaymentGatewayId.STRIPE],
    [PaymentMethodType.STRIPE_APPLE_PAY]: [PaymentGatewayId.STRIPE_APPLE_PAY],
    [PaymentMethodType.STRIPE_GOOGLE_PAY]: [PaymentGatewayId.STRIPE_GOOGLE_PAY],
    [PaymentMethodType.STRIPE_WEB]: [PaymentGatewayId.STRIPE_WEB],
    [PaymentMethodType.ADYEN_CARD]: [PaymentGatewayId.ADYEN],
    [PaymentMethodType.ADYEN_APPLE_PAY]: [PaymentGatewayId.ADYEN_APPLE_PAY],
    [PaymentMethodType.ADYEN_GOOGLE_PAY]: [PaymentGatewayId.ADYEN_GOOGLE_PAY],
    [PaymentMethodType.ADYEN_WEB]: [PaymentGatewayId.ADYEN_WEB],
    [PaymentMethodType.ADYEN_PAYMENT_LINK]: [PaymentGatewayId.ADYEN_PAYMENT_LINK],
    [PaymentMethodType.PAYU_APPLE_PAY]: [PaymentGatewayId.PAYU_APPLE_PAY],
    [PaymentMethodType.PAYU_GOOGLE_PAY]: [PaymentGatewayId.PAYU_GOOGLE_PAY],
    [PaymentMethodType.PAYU_CARD]: [PaymentGatewayId.PAYU],
    [PaymentMethodType.PAYU_WEB]: [PaymentGatewayId.PAYU_WEB],
    [PaymentMethodType.TWISTO]: [PaymentGatewayId.TWISTO],
    [PaymentMethodType.TWISTO_WEB]: [PaymentGatewayId.TWISTO_WEB],
    [PaymentMethodType.UPSK_FOOD_CARD]: [PaymentGatewayId.UPSK_FOOD_CARD],
    [PaymentMethodType.BTC]: [PaymentGatewayId.CONFIRMO_BTC],
    [PaymentMethodType.FINBRICKS]: [PaymentGatewayId.FINBRICKS],
};

export const paymentMethodsByPaymentGatewayId: Record<PaymentGatewayId, PaymentMethodType> = {
    [PaymentGatewayId.GPE]: PaymentMethodType.CARD,
    [PaymentGatewayId.BENEFIT_PLUS]: PaymentMethodType.BENEFIT_PLUS,
    [PaymentGatewayId.CSOB]: PaymentMethodType.CARD,
    [PaymentGatewayId.CSOB_TEST]: PaymentMethodType.CARD,
    [PaymentGatewayId.CSOB_APPLE_PAY]: PaymentMethodType.APPLE_PAY,
    [PaymentGatewayId.CSOB_GOOGLE_PAY]: PaymentMethodType.CSOB_GOOGLE_PAY,
    [PaymentGatewayId.CSOB_EDENRED]: PaymentMethodType.EDENRED,
    [PaymentGatewayId.CSOB_UP]: PaymentMethodType.CSOB_UP,
    [PaymentGatewayId.CSOB_LIDL]: PaymentMethodType.CSOB_LIDL,
    [PaymentGatewayId.CSOB_MALL_PAY]: PaymentMethodType.MALL_PAY,
    [PaymentGatewayId.SODEXO]: PaymentMethodType.SODEXO,
    [PaymentGatewayId.STRIPE]: PaymentMethodType.STRIPE_CARD,
    [PaymentGatewayId.STRIPE_APPLE_PAY]: PaymentMethodType.STRIPE_APPLE_PAY,
    [PaymentGatewayId.STRIPE_GOOGLE_PAY]: PaymentMethodType.STRIPE_GOOGLE_PAY,
    [PaymentGatewayId.STRIPE_WEB]: PaymentMethodType.STRIPE_WEB,
    [PaymentGatewayId.ADYEN]: PaymentMethodType.ADYEN_CARD,
    [PaymentGatewayId.ADYEN_APPLE_PAY]: PaymentMethodType.ADYEN_APPLE_PAY,
    [PaymentGatewayId.ADYEN_GOOGLE_PAY]: PaymentMethodType.ADYEN_GOOGLE_PAY,
    [PaymentGatewayId.ADYEN_WEB]: PaymentMethodType.ADYEN_WEB,
    [PaymentGatewayId.ADYEN_PAYMENT_LINK]: PaymentMethodType.ADYEN_PAYMENT_LINK,
    [PaymentGatewayId.PAYU_APPLE_PAY]: PaymentMethodType.PAYU_APPLE_PAY,
    [PaymentGatewayId.PAYU_GOOGLE_PAY]: PaymentMethodType.PAYU_GOOGLE_PAY,
    [PaymentGatewayId.PAYU]: PaymentMethodType.PAYU_CARD,
    [PaymentGatewayId.PAYU_WEB]: PaymentMethodType.PAYU_WEB,
    [PaymentGatewayId.TWISTO]: PaymentMethodType.TWISTO,
    [PaymentGatewayId.TWISTO_WEB]: PaymentMethodType.TWISTO_WEB,
    [PaymentGatewayId.UPSK_FOOD_CARD]: PaymentMethodType.UPSK_FOOD_CARD,
    [PaymentGatewayId.CONFIRMO_BTC]: PaymentMethodType.BTC,
    [PaymentGatewayId.FAKE]: PaymentMethodType.CARD,
    [PaymentGatewayId.FINBRICKS]: PaymentMethodType.FINBRICKS,
};

export const PaymentLinksGatewaysIds = [ PaymentGatewayId.ADYEN_PAYMENT_LINK ] as const;
export const WebPaymentGatewaysIds = [ PaymentGatewayId.PAYU_WEB, PaymentGatewayId.STRIPE_WEB, PaymentGatewayId.TWISTO_WEB, PaymentGatewayId.ADYEN_WEB ] as const;
export const WebPaymentMethodTypesIds = [ PaymentMethodType.PAYU_WEB, PaymentMethodType.STRIPE_WEB, PaymentMethodType.TWISTO_WEB, PaymentMethodType.ADYEN_WEB ];
export const ApplePayMethodTypesIds = [ PaymentMethodType.APPLE_PAY, PaymentMethodType.PAYU_APPLE_PAY, PaymentMethodType.STRIPE_APPLE_PAY, PaymentMethodType.ADYEN_APPLE_PAY ] as const;
export const GooglePayMethodTypesIds = [ PaymentMethodType.CSOB_GOOGLE_PAY, PaymentMethodType.PAYU_GOOGLE_PAY, PaymentMethodType.STRIPE_GOOGLE_PAY, PaymentMethodType.ADYEN_GOOGLE_PAY ] as const;

export const CsobPaymentGatewaysIds = [ PaymentGatewayId.CSOB, PaymentGatewayId.CSOB_GOOGLE_PAY, PaymentGatewayId.CSOB_APPLE_PAY, PaymentGatewayId.CSOB_EDENRED, PaymentGatewayId.CSOB_MALL_PAY, PaymentGatewayId.CSOB_UP, PaymentGatewayId.CSOB_TEST, PaymentGatewayId.CSOB_LIDL ] as const;
export const PayuPaymentGatewaysIds = [ PaymentGatewayId.PAYU, PaymentGatewayId.PAYU_GOOGLE_PAY, PaymentGatewayId.PAYU_APPLE_PAY, PaymentGatewayId.PAYU_WEB ] as const;
export const AdyenPaymentGatewaysIds = [ PaymentGatewayId.ADYEN, PaymentGatewayId.ADYEN_GOOGLE_PAY, PaymentGatewayId.ADYEN_APPLE_PAY, PaymentGatewayId.ADYEN_WEB ] as const;
export const StripePaymentGatewaysIds = [ PaymentGatewayId.STRIPE, PaymentGatewayId.STRIPE_GOOGLE_PAY, PaymentGatewayId.STRIPE_APPLE_PAY, PaymentGatewayId.STRIPE_WEB ] as const;
export const TwistoPaymentGatewaysIds = [ PaymentGatewayId.TWISTO, PaymentGatewayId.TWISTO_WEB ] as const;

export const GooglePayGatewaysIds = [ PaymentGatewayId.CSOB_GOOGLE_PAY, PaymentGatewayId.PAYU_GOOGLE_PAY, PaymentGatewayId.STRIPE_GOOGLE_PAY, PaymentGatewayId.ADYEN_GOOGLE_PAY ] as const;
export const ApplePayGatewaysIds = [ PaymentGatewayId.CSOB_APPLE_PAY, PaymentGatewayId.PAYU_APPLE_PAY, PaymentGatewayId.STRIPE_APPLE_PAY, PaymentGatewayId.ADYEN_APPLE_PAY ] as const;

const createApplePayMerchantIdentifier = <T extends {
    sandbox: Record<(typeof ApplePayGatewaysIds)[number], Readonly<string>>;
    production: Record<(typeof ApplePayGatewaysIds)[number], Readonly<string>>;
}>(value: T): T => value;

export const ApplePayMerchantIdentifier = createApplePayMerchantIdentifier({
    sandbox: {
        CSOB_APPLE_PAY: 'merchant.com.qerko',
        PAYU_APPLE_PAY: 'merchant.com.qerkoPayUSandbox',
        STRIPE_APPLE_PAY: 'merchant.com.qerkoStripe',
        ADYEN_APPLE_PAY: 'merchant.com.adyenSandbox',
        PAYU_WEB: 'merchant.com.qerkoPayUSandbox',
        STRIPE_WEB: 'merchant.com.qerkoStripe',
        ADYEN_WEB: 'merchant.com.adyenSandbox',
    } as const,
    production: {
        CSOB_APPLE_PAY: 'merchant.csob.production',
        PAYU_APPLE_PAY: 'merchant.com.qerkoPayUProduction',
        STRIPE_APPLE_PAY: 'merchant.com.qerkoStripe',
        ADYEN_APPLE_PAY: 'merchant.com.adyenProduction',
        PAYU_WEB: 'merchant.com.qerkoPayUProduction',
        STRIPE_WEB: 'merchant.com.qerkoStripe',
        ADYEN_WEB: 'merchant.com.adyenProduction',
    } as const,
});
