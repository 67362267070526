import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import type { SxProps, Theme } from '@mui/material';

export const ReservationBoxLabelValue = (
    {
        label,
        value,
        sxValue,
    }: {
        label: string;
        value: string;
        sxValue?: SxProps<Theme>;
    }
) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
            <Typography sx={sxValue}>{value}</Typography>
        </Box>
    );
};
