import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        backdrop: {
            backgroundColor: '#ffff',
            color: '#009900',
            zIndex: 999999,
        },
    }),
);

export const FullScreenLoader = (props: {
    componentOrShow: React.ReactNode | false;
}) => {
    const classes = useStyles();

    return (
        <>
            <Backdrop className={classes.backdrop} open={props.componentOrShow === false} transitionDuration={{
                appear: 0,
                enter: 0,
                exit: 1000,
            }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.componentOrShow !== false && props.componentOrShow}
        </>
    );
};
