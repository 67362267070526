import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import UAParser from 'ua-parser-js';
import Button from '@mui/material/Button';
import { ReservationBoxWhiteWrap } from 'restaurant/modules/reservation/ReservationBoxWhiteWrap';

import type { z } from 'zod';
import type { RestaurantQrSchema } from 'src/shared/restaurantTypes';

export const RestaurantTakeawayBox = ( {
    restaurant,
}: {
    restaurant: z.infer<typeof RestaurantQrSchema>['restaurant'];
}) => {
    const { t } = useTranslation('restaurant.takeaway');
    const [isMobile, setIsMobile] = useState(false);

    useState(() => {
        const uaParser = new UAParser();
        const { type } = uaParser.getDevice();

        setIsMobile(type === 'mobile');
    });

    return (
        <ReservationBoxWhiteWrap>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', flexDirection: 'column', pt: '2rem', gap: '1rem' }}>
                <Typography sx={{ fontSize: '1.5rem' }} variant="h1">{t('header')}</Typography>
                <Typography paragraph sx={{ mb: '1rem', color: '#6E7587', fontSize: '.90rem' }}>{t('description')}</Typography>

                {!isMobile && (
                    <Box sx={{
                        p: '.5rem',
                        borderRadius: '14px',
                        border: '1px solid #E9EDEC',
                        width: '146px',
                        mb: '3rem',
                    }}
                    >
                        <img
                            alt="take away qr code"
                            src={`https://qr-generator.qerko.com/?theme=logo&scale=2&code=https%3A%2F%2Fqerko.com%2F~qr%2Frid%2F${restaurant.id}`}
                            style={{
                                width: '100%',
                                margin: 0,
                                padding: 0,
                                border: 0,
                                float: 'left',
                            }}
                        />
                    </Box>
                )}
                {isMobile && (
                    <Box sx={{ mb: '3rem' }}>
                        <Button color="primary" href={`https://qerko.com/~qr/restaurant-id/${restaurant.id}`} rel="noopener noreferrer" size="large" target="_blank" variant="contained">
                            {t('button')}
                        </Button>
                    </Box>
                )}
            </Box>
        </ReservationBoxWhiteWrap>
    );
};
