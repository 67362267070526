import { z } from 'zod';
import { useFormik } from 'formik';
import { useMemo } from 'react';

import { logger } from '../common/utils/logger';

import { bigNumber } from './numberUtils';

import type { FormikConfig } from 'formik';
import type { DeepNullable } from 'ts-essentials';
import type {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    FormikErrors,
    FormikState,
    FormikTouched,
} from 'formik/dist/types';
import type * as React from 'react';
import type { ZodType } from 'zod';
import type { SelectChangeEvent } from '@mui/material/Select/SelectInput';

const createFormikZodSchemaValidator = <TZodSchema extends z.ZodSchema<any>>(zodSchema: TZodSchema) => (values: z.infer<TZodSchema>) => {
    const errors = {} as Record<string, unknown>;
    const validatedValues = zodSchema.safeParse(values);
    if (validatedValues.success) {
        return errors;
    }

    logger.debug('Formik-validate: ', validatedValues.error.errors, values);
    // process all errors
    validatedValues.error.errors.forEach((error) => {
        errors[error.path.join('.')] = error.message;

        // process potential union errors
        if ('unionErrors' in error) {
            const intersection = (arrays: z.ZodIssue[][]): z.ZodIssue[] => {
                if (arrays.length === 0) return [];

                return arrays.reduce((acc, array) => {
                    return acc.filter((item1) => array.some((item2) => item1.message === item2.message && item1.path.join() === item2.path.join()));
                }, arrays[0]);
            };

            // return all errors that occurs in each of union error, to prevent error caused by union discriminant
            intersection(error.unionErrors.map((e) => e.errors)).forEach((error) => {
                errors[error.path.join('.')] = error.message;
            });
        }
    });

    return errors;
};

function getValue<T extends ZodType<any>>(val: string, obj: unknown, type: T = z.string() as unknown as T): z.infer<T> {
    const array = val.split('.');
    const error = type.safeParse(val);
    if (!error.success) logger.debug(error);
    //@ts-expect-error
    return array.reduce((prev, curr) => prev && prev[curr], obj);
}

// @ts-expect-error
interface QerkoFormikConfig<InputValues, OutputValues> extends FormikConfig<OutputValues> {
    initialValues: DeepNullable<InputValues>;
}

export const useFormikFromForZod = <TZodSchema extends z.ZodSchema<any>>(zodSchema: TZodSchema, config: QerkoFormikConfig<z.input<TZodSchema>, z.output<TZodSchema>>): FormikType<z.infer<TZodSchema>> => {
    const { validate, ...rest } = config;
    const zodValidator = createFormikZodSchemaValidator(zodSchema);

    const formik = useFormik<z.infer<TZodSchema>>({
        validate: async (values) => {
            let errors: z.infer<TZodSchema> = {};

            if (validate !== undefined) {
                errors = (await validate(values)) ?? {};
            }

            return {
                ...zodValidator(values),
                ...errors,
            };
        },
        validateOnBlur: false,
        validateOnChange: false,
        ...rest,
    }) as FormikType<z.infer<TZodSchema>>;

    return useMemo<z.infer<TZodSchema>>(() => {
        formik.stringInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onBlur: (event: React.ChangeEvent<any>) => {
                const value = (event.target.value || '').trim();
                formik.setFieldValue(event.target.name, value || null);
            },
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value || event.target.value === 0 ? event.target.value : null),
            value: getValue(name, formik.values) || '',
        });
        formik.stringNotNullInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onBlur: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, (event.target.value || '').trim()),
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value),
            value: getValue(name, formik.values) || '',
        });
        formik.timeInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value || event.target.value === 0 ? `${event.target.value}:00`.substr(0, 8) : null),
            value: (getValue(name, formik.values) || '').substr(0, 5),
        });
        formik.integerParserProps = () => ({
            onChange: (event: SelectChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value || event.target.value === 0 ? parseInt(event.target.value) : null),
        });
        formik.integerInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            value: getValue(name, formik.values) || 0,
            ...formik.integerParserProps(name),
        });
        formik.stringNumberInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (event: React.ChangeEvent<any>) => {
                let num = event.target.value;
                if (num === '') num = '0';
                num = bigNumber(num);
                if (!num.isFinite()) return;
                formik.setFieldValue(event.target.name, num.toString());
            },
            value: getValue(name, formik.values) || 0,
        });
        formik.integerOrNullInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            value: getValue(name, formik.values) || null,
            ...formik.integerParserProps(name),
        });
        formik.splittableStringInputProps = (name: string, splitChar = ',', joinChar = ', ') => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (e: React.ChangeEvent<any>) => formik.setFieldValue(name, e.target.value && e.target.value.split(splitChar).map((item: string) => item.trim())),
            value: getValue(name, formik.values, z.array(z.string())).join(joinChar) || '',
        });
        formik.percentInputProps = (name: string, maximum = 1, minimum = 0) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (event: React.ChangeEvent<any>) => {
                const value = event.target.value;
                if (!value) {
                    formik.setFieldValue(event.target.name, null);
                } else {
                    const decimalValue = bigNumber(event.target.value).div(100).toNumber();
                    if (decimalValue > maximum) {
                        formik.setFieldValue(event.target.name, maximum);
                    } else if (decimalValue < minimum) {
                        formik.setFieldValue(event.target.name, minimum);
                    } else {
                        formik.setFieldValue(event.target.name, decimalValue);
                    }
                }
            },
            value: bigNumber(getValue(name, formik.values) ?? 0).multipliedBy(100).toNumber().toString() || '',
        });
        formik.percentStringInputProps = (name: string, maximum = 1, minimum = 0) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (event: React.ChangeEvent<any>) => {
                const value = event.target.value;
                if (!value) {
                    formik.setFieldValue(event.target.name, '0');
                } else {
                    const decimalValue = bigNumber(event.target.value).div(100).toNumber();
                    if (decimalValue > maximum) {
                        formik.setFieldValue(event.target.name, `${maximum}`);
                    } else if (decimalValue < minimum) {
                        formik.setFieldValue(event.target.name, `${minimum}`);
                    } else {
                        formik.setFieldValue(event.target.name, `${decimalValue}`);
                    }
                }
            },
            value: bigNumber(getValue(name, formik.values) ?? 0).multipliedBy(100).toNumber().toString() || '',
        });
        formik.floatInputProps = (name: string) => ({
            error: formik.errors[name] !== undefined,
            helperText: formik.errors[name] as string,
            name,
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value || event.target.value === 0 ? parseFloat(event.target.value) : null),
            value: getValue(name, formik.values),
        });
        formik.checkboxProps = (name: string) => ({
            name,
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.checked),
            value: !!getValue(name, formik.values),
        });
        formik.selectProps = (name: string) => ({
            name,
            onChange: (event: SelectChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value ? event.target.value : null),
            value: getValue(name, formik.values) || '',
        });
        formik.selectNotNullProps = (name: string) => ({
            name,
            onChange: (event: React.ChangeEvent<any>) => formik.setFieldValue(event.target.name, event.target.value),
            value: getValue(name, formik.values) || '',
        });
        formik.dateProps = (name: string) => ({
            name,
            value: getValue(name, formik.values) || null,
        });
        formik.valueProps = (name: string) => ({
            name,
            value: getValue(name, formik.values) || '',
        });

        return formik;
    }, [ formik ]);
};

export type HandleChange = {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
};

export type HandleBlur = {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
};

export interface FormikType<Values> {
    initialValues: DeepNullable<Values>;
    initialErrors: FormikErrors<unknown>;
    initialTouched: FormikTouched<unknown>;
    initialStatus: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    stringInputProps: (name: string, splitChar?: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    stringNotNullInputProps: (name: string, splitChar?: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    timeInputProps: (name: string, splitChar?: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    splittableStringInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    checkboxProps: (name: string) => ({
        name: string;
        value: boolean;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    selectProps: (name: string) => ({
        name: string;
        value: string;
        onChange: (e: SelectChangeEvent<any>) => void;
    });
    selectNotNullProps: (name: string) => ({
        name: string;
        value: string;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    dateProps: (name: string) => ({
        name: string;
        value: Date | null;
    });
    valueProps: (name: string) => ({
        name: string;
        value: string;
    });
    stringNumberInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    integerInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    integerOrNullInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    integerParserProps: (name: string) => ({
        onChange: (e: SelectChangeEvent<any>) => void;
    });
    percentInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    percentStringInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    floatInputProps: (name: string) => ({
        name: string;
        value: string;
        error: boolean;
        helperText: React.ReactNode | undefined;
        onChange: (e: React.ChangeEvent<any>) => void;
    });
    handleReset: (e: any) => void;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void;
    setErrors: (errors: FormikErrors<Values>) => void;
    setFormikState: (stateOrCb: FormikState<Values> | ((state: FormikState<Values>) => FormikState<Values>)) => void;
    setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    setFieldError: (field: string, value: string | undefined) => void;
    setStatus: (status: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    setTouched: (touched: FormikTouched<Values>, shouldValidate?: boolean | undefined) => any;
    setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean | undefined) => any;
    submitForm: () => Promise<any>;
    validateForm: (values?: Values) => Promise<FormikErrors<Values>>;
    validateField: (name: string) => Promise<void> | Promise<string | undefined>;
    isValid: boolean;
    dirty: boolean;
    unregisterField: (name: string) => void;
    registerField: (name: string, { validate }: any) => void;
    getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
    getFieldMeta: (name: string) => FieldMetaProps<any>;
    getFieldHelpers: (name: string) => FieldHelperProps<any>;
    validateOnBlur: boolean;
    validateOnChange: boolean;
    validateOnMount: boolean;
    values: Values;
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
    isSubmitting: boolean;
    isValidating: boolean;
    status?: any;
    submitCount: number;
}

export const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = (error) => reject(error);
    });
};

