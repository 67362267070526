import SvgIcon from '@mui/material/SvgIcon';

import type { SvgIconProps } from '@mui/material';

export const ForkLiftIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 47 60">
        <svg fill="none" height="60" width="47" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M43.338.317A3 3 0 0 1 44.996 3v26.918L46.2 51.592C46.454 56.158 42.82 60 38.246 60c-4.573 0-8.208-3.842-7.954-8.408L31.325 33h-1.329a3 3 0 0 1-3-3v-.044l.001-.103.006-.375a67.553 67.553 0 0 1 .387-5.81c.382-3.441 1.174-8.011 2.923-11.51 1.703-3.405 4.2-6.293 6.187-8.28a42.532 42.532 0 0 1 2.574-2.371 32.578 32.578 0 0 1 1.023-.831l.067-.051.02-.016.008-.005.002-.002.002-.002a3 3 0 0 1 3.142-.283Zm-4.342 9.682c-1.233 1.431-2.437 3.084-3.316 4.843-1.251 2.501-1.96 6.181-2.327 9.49-.108.97-.183 1.876-.236 2.668h5.88V9.999ZM37.334 33l-1.051 18.925a1.966 1.966 0 1 0 3.927 0L39.158 33h-1.824ZM20.764 28.285a13.122 13.122 0 0 1-1.987 2.122l1.177 21.185C20.208 56.158 16.574 60 12 60c-4.573 0-8.208-3.842-7.954-8.408l1.177-21.184a13.118 13.118 0 0 1-1.987-2.123C1.182 25.546 0 21.9 0 18c0-3.845 1.148-8.096 3.016-11.446C4.769 3.41 7.793 0 12 0s7.231 3.41 8.984 6.554C22.852 9.904 24 14.154 24 18c0 3.9-1.182 7.546-3.236 10.285ZM8.256 9.475C6.866 11.97 6 15.219 6 18c0 2.727.833 5.081 2.036 6.685C9.239 26.29 10.671 27 12 27c1.33 0 2.761-.711 3.964-2.315C17.167 23.081 18 20.727 18 18c0-2.782-.866-6.031-2.256-8.524C14.239 6.776 12.764 6 12 6c-.763 0-2.24.777-3.744 3.476Zm1.78 42.45L11.089 33h1.824l1.051 18.925a1.966 1.966 0 1 1-3.926 0Z" fill="#C6D1D2" fillRule="evenodd" /></svg>
    </SvgIcon>
);

