import { z } from 'zod';

import {
    Country,
    Currency,
    CustomHelpDeskZodSchema,
    EmailSchema, HttpUrlSchema,
    IntegerSchema, JsonSchema,
    NfcSerialNumberSchema,
    NonEmptyString16Schema,
    NonEmptyString255Schema,
    NonEmptyString32Schema,
    NonEmptyString512Schema,
    NonEmptyString64Schema,
    NonEmptyStringSchema,
    NonNegativeDecimalStringSchema,
    NonNegativeFloatSchema,
    PercentStringSchema,
    TaxIdHungarySchema,
    TimeStringSchema,
    TimeWithMillisecondsStringSchema,
    Timezone,
    UuidSchema, VATNumberSchema,
    ZipCodeSchema,
} from './types';
import { BusinessType, RestaurantPayoutTipCalculationMethod, RestaurantTipHandling, TableTagMode } from './enums';
import { PaymentMethodType } from './PaymentGatewayTypes';

import type { Integer } from './types';

export enum RestaurantBankAccountStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export enum RestaurantSzamlazzStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export const TableTagsSchema = z.array(
    z.union([
        IntegerSchema,
        z.tuple([IntegerSchema, IntegerSchema]),
    ]),
)
    // Transform to better format it will be always tuple[]
    .transform((tuples): [Integer, Integer][] => tuples.map((tuple) => typeof tuple === 'number' ? [tuple, tuple] : tuple ))
    .superRefine((tableTags, context) => {
        const MAX_RANGE = 150; // Maximum range between tags

        tableTags.forEach((tableTag) => {
            const [start, end] = tableTag;

            if (end < start) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'End tag must be greater than start tag.',
                });
            }

            if ((end - start) > MAX_RANGE) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: `Range between tags can be maximum ${MAX_RANGE}.`,
                });
            }
        });

    });

export type TableTags = z.output<typeof TableTagsSchema>;

export const DeliveryHoursSchema = z.object({
    day: IntegerSchema,
    idRestaurant: UuidSchema.optional(), // it doesn`t make sense
    openFrom: TimeStringSchema,
    openTo: TimeStringSchema,
}).array();

export const OpeningHoursSchema = z.object({
    day: IntegerSchema,
    idRestaurant: UuidSchema.optional(), // it doesn`t make sense
    openFrom: TimeStringSchema,
    openTo: TimeStringSchema,
}).array();

export const ProfileSchema = z.object({
    aboutRestaurant: NonEmptyString512Schema.nullable(),
    phone: NonEmptyStringSchema.nullable(),
    wifis: z.object({
        name: NonEmptyStringSchema,
        pwd: z.string(),
    }).array().default([]),
    idRestaurantType: IntegerSchema,
    icon: NonEmptyString255Schema.nullable().default(null),
    hasGarden: z.boolean().default(false),
    hasSportsBroadcasting: z.boolean().default(false),
    hasAirConditioning: z.boolean().default(false),
    restaurantLogo: z.object({
        id: UuidSchema,
    }).nullable().default(null),
    restaurantImage: z.object({
        id: UuidSchema,
    }).nullable().default(null),
    externalReservationUrl: HttpUrlSchema.nullable(),
    externalOrderingUrl: HttpUrlSchema.nullable(),
});

export const ProfileReadSchema = ProfileSchema.extend({
    aboutRestaurant: NonEmptyString512Schema.nullable(),
    phone: NonEmptyStringSchema.nullable(),
    wifis: z.object({
        name: NonEmptyStringSchema,
        pwd: z.string(),
    }).array().default([]),
    idRestaurantType: IntegerSchema,
    icon: NonEmptyString255Schema.nullable().default(null),
    hasGarden: z.boolean().default(false),
    hasSportsBroadcasting: z.boolean().default(false),
    hasAirConditioning: z.boolean().default(false),
    restaurantLogo: z.object({
        id: UuidSchema,
        name: NonEmptyString255Schema,
        url: NonEmptyStringSchema,
    }).nullable(),
    restaurantImage: z.object({
        id: UuidSchema,
        name: NonEmptyString255Schema,
        url: NonEmptyStringSchema,
    }).nullable(),
});

export const ShiftConfigSchema = z.object({
    changeTime: TimeWithMillisecondsStringSchema.nullable().optional(),
    nfcSerialNumber: NfcSerialNumberSchema.nullable(),
    restaurantId: UuidSchema.nullable().optional(), // it doesn`t make sense
});

export const RestaurantProvisionConfigSchema = z.object({
    isAllowed: z.boolean(),
    provisionMax: NonNegativeDecimalStringSchema,
    transactionProvision: NonNegativeDecimalStringSchema,
    transactionProvisionAbs: NonNegativeDecimalStringSchema,
    transactionProvisionExcludeTip: z.boolean(),
    transactionProvisionMax: NonNegativeDecimalStringSchema,
    tipProvision: NonNegativeDecimalStringSchema,
    tipProvisionAbs: NonNegativeDecimalStringSchema,
    tipProvisionMinTip: NonNegativeDecimalStringSchema,
    tipProvisionExcludeMinTip: z.boolean(),
    tipProvisionMax: NonNegativeDecimalStringSchema,
    additionalDataJson: z.record(z.string(), JsonSchema).nullable(),
});

export type RestaurantProvisionConfig = z.output<typeof RestaurantProvisionConfigSchema>;

export const RestaurantBaseSchema = z.object({
    accountManagerId: UuidSchema.nullable().optional(),
    addressCity: NonEmptyString64Schema,
    addressCountry: z.string(),
    addressDescriptiveNumber: NonEmptyString16Schema,
    addressStreet: NonEmptyString64Schema,
    addressZipCode: ZipCodeSchema,
    allowedDataSharing: z.boolean().optional(),
    businessType: z.nativeEnum(BusinessType),
    countryId: z.nativeEnum(Country),
    countryRegionId: UuidSchema,
    currencies: z.nativeEnum(Currency).array(),
    customHelpDesk: CustomHelpDeskZodSchema,
    defaultCurrency: z.nativeEnum(Currency),
    defaultTip: NonNegativeFloatSchema,
    deliveryHours: DeliveryHoursSchema.optional(),
    hasLogo: z.boolean(),
    identificationNumber: NonEmptyString32Schema,
    installationBoxPrice: NonNegativeFloatSchema.optional(),
    isActive: z.boolean(),
    isEnabledCustomHelpDesk: z.boolean(),
    isPilsnerEnabled: z.boolean(),
    isTesting: z.boolean().optional(),
    latitude: NonNegativeFloatSchema,
    longitude: NonNegativeFloatSchema,
    minPaymentsExcludedFromSaRewards: IntegerSchema.optional(),
    minimumPaymentsPerMonth: IntegerSchema.optional(),
    name: NonEmptyString255Schema,
    openingHours: OpeningHoursSchema.optional(),
    pilsnerBussinessNumber: z.string().nullable(),
    pipeDriveDealId: IntegerSchema.nullable(),
    pipeDriveOrganizationId: IntegerSchema.nullable(),
    profile: z.object({
        aboutRestaurant: NonEmptyString512Schema.nullable(),
        phone: NonEmptyStringSchema.nullable(),
        wifis: z.object({
            name: NonEmptyStringSchema,
            pwd: z.string(),
        }).array().default([]),
        idRestaurantType: IntegerSchema,
        icon: NonEmptyString255Schema.nullable().default(null),
        hasGarden: z.boolean().default(false),
        hasSportsBroadcasting: z.boolean().default(false),
        hasAirConditioning: z.boolean().default(false),
        restaurantLogo: z.object({
            id: UuidSchema,
        }).nullable().default(null),
        restaurantImage: z.object({
            id: UuidSchema,
        }).nullable().default(null),
        externalReservationUrl: HttpUrlSchema.nullable(),
        externalOrderingUrl: HttpUrlSchema.nullable(),
    }),
    provisionConfig: z.record(z.nativeEnum(PaymentMethodType), RestaurantProvisionConfigSchema).optional(),
    qerkoBonusNewUser: NonNegativeFloatSchema.optional(),
    qerkoBonusNewUserMinAmount: NonNegativeFloatSchema.optional(),
    qerkoBonusNewUserUntilDate: IntegerSchema.nullable(),
    salesmanCutTillOverride: IntegerSchema.nullable().optional(),
    salesmanId: UuidSchema.nullable().optional(),
    serviceFee: PercentStringSchema.nullable().default(null),
    shiftConfig: ShiftConfigSchema.nullable().optional(),
    showInMap: z.boolean().optional(),
    showPosDriverSettings: z.boolean(),
    showWaiterNameInPayments: z.boolean().optional(),
    taxIdentificationNumber: VATNumberSchema.nullable(),
    termsUrl: HttpUrlSchema.nullable().default(null),
    timezone: z.nativeEnum(Timezone),
    tipHandling: z.nativeEnum(RestaurantTipHandling),
    tipPayoutCalculationMethod: z.nativeEnum(RestaurantPayoutTipCalculationMethod),
    tipReductionForPersonel: z.number(),
    url: HttpUrlSchema.nullable(),
    useMinimalBillingSince: IntegerSchema.nullable().optional(),
    variableSymbol: NonNegativeDecimalStringSchema,
});

const SuperRestaurantCreateUpdateDataSchema = RestaurantBaseSchema.extend({
    isDeliveryAvailable: z.boolean().optional(),
    isTableOrderingAvailable: z.boolean().optional(),
    isTakeawayAvailable: z.boolean().optional(),
    isToBillOrderingAvailable: z.boolean().optional(),
    okp: NonEmptyStringSchema.nullable().optional(),
    // eslint-disable-next-line no-restricted-syntax
    permissions: z.unknown(),
    restaurantTags: TableTagsSchema,
    restaurantTagsAction: IntegerSchema.default(IntegerSchema.parse(0)),
    restu: z.any(), // @todo deprecated (Restu is not company anymore)
    tableTags: TableTagsSchema,
    tableTagsAction: IntegerSchema,
    openStreetMapNodeId: NonEmptyString64Schema.nullable().optional(),
});

export const RestaurantBaseCreateDataSchema = z.object({
    addressCity: NonEmptyString64Schema,
    addressCountry: z.string(),
    addressDescriptiveNumber: NonEmptyString16Schema,
    addressStreet: NonEmptyString64Schema,
    addressZipCode: ZipCodeSchema,
    businessType: z.nativeEnum(BusinessType),
    countryRegionId: UuidSchema,
    currencies: z.nativeEnum(Currency).array(),
    defaultCurrency: z.nativeEnum(Currency),
    deliveryHours: DeliveryHoursSchema,
    identificationNumber: NonEmptyString32Schema,
    isTesting: z.boolean(),
    latitude: NonNegativeFloatSchema,
    longitude: NonNegativeFloatSchema,
    name: NonEmptyString255Schema,
    openingHours: OpeningHoursSchema,
    profile: ProfileSchema,
    showInMap: z.boolean(),
    taxIdentificationNumber: VATNumberSchema.nullable(),
    url: HttpUrlSchema.nullable(),
});

export const RestaurantUpdateDataSchema = z.object({
    addressCity: NonEmptyString64Schema,
    addressCountry: z.string(),
    addressDescriptiveNumber: NonEmptyString16Schema,
    addressStreet: NonEmptyString64Schema,
    addressZipCode: ZipCodeSchema,
    countryRegionId: UuidSchema,
    deliveryHours: DeliveryHoursSchema.optional(),
    isDeliveryAvailable: z.boolean().optional(),
    isTakeawayAvailable: z.boolean().optional(),
    latitude: NonNegativeFloatSchema,
    longitude: NonNegativeFloatSchema,
    name: NonEmptyString255Schema,
    openingHours: OpeningHoursSchema.optional(),
    profile: ProfileSchema,
    url: HttpUrlSchema.nullable(),
});

export const SuperRestaurantBaseCreateDataSchema = SuperRestaurantCreateUpdateDataSchema.extend({
    // @todo is it required in create? update restaurant is sending this prop.
    isPilsnerEnabled: z.boolean().optional(),
    // @todo is it required in create? update restaurant is sending this prop.
    restaurantTags: TableTagsSchema.optional(),
    // @todo is it required in create? update restaurant is sending this prop.
    showPosDriverSettings: z.boolean().optional(),
    // @todo is it required in create? update restaurant is sending this prop.
    tableTagsAction: IntegerSchema.optional(),
    // @todo is it required in create? update restaurant is sending this prop.
    tipReductionForPersonel: z.number().optional(),
});

export const RestaurantPermissionSchema = z.object({
    API_KEYS: z.boolean(),
    CUSTOMERS: z.boolean(),
    DASHBOARD: z.boolean(),
    FEEDBACK: z.boolean(),
    LOYALTY: z.boolean(),
    MENU: z.boolean(),
    PAYMENTS: z.boolean(),
    PAYOUT: z.boolean(),
    PILSNER_CAMPAIGN: z.boolean(),
    PROFILE: z.boolean(),
    QR: z.boolean(),
    RESERVATION: z.boolean(),
    SEE_ORIGINAL_TIP: z.boolean(),
    SETTINGS: z.boolean(),
    TABLES: z.boolean(),
    UNLIMITED_TIMESPAN: z.boolean(),
    USERS: z.boolean(),
});

export enum PermissionGroupName {
    PERSONEL = 'PERSONEL',
    OWNER = 'OWNER',
}

export const ShortRestaurantReadDataSchema = z.object({
    id: UuidSchema,
    name: NonEmptyString255Schema,
    isActive: z.boolean(),
    isTesting: z.boolean(),
    showWaiterNameInPayments: z.boolean(),
    tipReductionForPersonel: z.number(),
    variableSymbol: NonNegativeDecimalStringSchema,
    identificationNumber: NonEmptyString32Schema,
    currencies: z.array(z.nativeEnum(Currency)),
    defaultCurrency: z.nativeEnum(Currency),
    isPilsnerEnabled: z.boolean(),
    showPosDriverSettings: z.boolean(),
    shiftConfig: z.object({
        changeTime: TimeStringSchema,
        nfcSerialNumber: NfcSerialNumberSchema,
    }).strip().nullable(),
    countryId: z.nativeEnum(Country),
    isAdyenOnboardingAllowed: z.boolean(),
    isPayuOnboardingAllowed: z.boolean(),
    isQerkoOnboardingAllowed: z.boolean(),
});

export const RestaurantPermissionsSchema = z.object({
    permissions: RestaurantPermissionSchema,
});

export const ShortRestaurantWithPermissionsReadDataSchema = ShortRestaurantReadDataSchema.merge(RestaurantPermissionsSchema);

export const BaseRestaurantReadDataSchema = ShortRestaurantReadDataSchema.extend({
    accountManagerId: z.string().nullable(),
    addressCity: NonEmptyStringSchema,
    addressCountry: z.string(),
    addressDescriptiveNumber: NonEmptyStringSchema,
    addressStreet: NonEmptyStringSchema,
    addressZipCode: ZipCodeSchema,
    allowedDataSharing: z.boolean(),
    businessType: z.string(),
    countryRegionId: NonEmptyStringSchema,
    customHelpDesk: z.object({ name: NonEmptyStringSchema, email: EmailSchema }).nullable().optional(),
    defaultTip: z.number(),
    deliveryHours: DeliveryHoursSchema,
    driver: z.object({ name: z.string(), email: z.string(), activated: z.boolean().optional() }).optional(),
    hasLogo: z.boolean().optional(),
    installationBoxPrice: z.number(),
    isEnabledCustomHelpDesk: z.boolean().optional(),
    latitude: z.number(),
    longitude: z.number(),
    minPaymentsExcludedFromSaRewards: z.number(),
    minimumPaymentsPerMonth: z.number(),
    okp: NonEmptyStringSchema.optional().nullable(),
    openingHours: OpeningHoursSchema,
    pilsnerBussinessNumber: z.string().optional(),
    pipeDriveDealId: IntegerSchema.nullable(),
    pipeDriveOrganizationId: IntegerSchema.nullable(),
    // eslint-disable-next-line no-restricted-syntax
    profile: z.unknown(),
    provisionConfig: z.record(z.nativeEnum(PaymentMethodType), RestaurantProvisionConfigSchema.extend({
        name: NonEmptyStringSchema,
    })).optional(),
    qerkoBonusNewUser: z.number(),
    qerkoBonusNewUserMinAmount: z.number(),
    qerkoBonusNewUserUntilDate: IntegerSchema.nullable(),
    restu: z.any(), // @todo deprecated (Restu is not company anymore)
    salesmanCutTillOverride: IntegerSchema.nullable(),
    salesmanId: UuidSchema.nullable(),
    serviceFee: PercentStringSchema.nullable(),
    showInMap: z.boolean(),
    taxIdentificationNumber: VATNumberSchema.nullable(),
    taxPayerIdentificationNumber: TaxIdHungarySchema.optional(),
    termsUrl: HttpUrlSchema.nullable(),
    timezone: z.string(),
    tipHandling: z.nativeEnum(RestaurantTipHandling),
    tipPayoutCalculationMethod: z.nativeEnum(RestaurantPayoutTipCalculationMethod),
    url: HttpUrlSchema.nullable(),
    useMinimalBillingSince: IntegerSchema.nullable(),
    openStreetMapNodeId: NonEmptyString64Schema.nullable(),
    legalEntityId: UuidSchema,
    isAdyenOnboardingAllowed: z.boolean(),
    isPayuOnboardingAllowed: z.boolean(),
    isQerkoOnboardingAllowed: z.boolean(),
});

export const RestaurantWithPermissionsReadDataSchema = BaseRestaurantReadDataSchema.merge(RestaurantPermissionsSchema);

export const RestaurantCreateDataSchema = z.union( [
    RestaurantBaseCreateDataSchema.extend({
        countryId: z.union([z.literal(Country.CZ), z.literal(Country.DE), z.literal(Country.AT)]),
    }),
    RestaurantBaseCreateDataSchema.extend({
        countryId: z.literal(Country.SK),
        okp: NonEmptyStringSchema.nullable().optional(),
    }),
    RestaurantBaseCreateDataSchema.extend({
        countryId: z.literal(Country.HU),
        taxPayerIdentificationNumber: TaxIdHungarySchema.optional(),
    }),
]);

export const SuperRestaurantCreateDataSchema = z.union([
    SuperRestaurantBaseCreateDataSchema.extend({
        countryId: z.union([z.literal(Country.CZ), z.literal(Country.DE), z.literal(Country.AT)]),
    }),
    SuperRestaurantBaseCreateDataSchema.extend({
        countryId: z.literal(Country.SK),
        okp: NonEmptyStringSchema.nullable().optional(),
    }),
    SuperRestaurantBaseCreateDataSchema.extend({
        countryId: z.literal(Country.HU),
        taxPayerIdentificationNumber: TaxIdHungarySchema.optional(),
    }),
]);

export const SuperRestaurantUpdateDataSchema = z.union([
    SuperRestaurantCreateUpdateDataSchema.extend({
        id: UuidSchema,
        countryId: z.union([z.literal(Country.CZ), z.literal(Country.DE), z.literal(Country.AT)]),
    }),
    SuperRestaurantCreateUpdateDataSchema.extend({
        id: UuidSchema,
        countryId: z.literal(Country.SK),
        okp: NonEmptyStringSchema.nullable().optional(),
    }),
    SuperRestaurantCreateUpdateDataSchema.extend({
        id: UuidSchema,
        countryId: z.literal(Country.HU),
        taxPayerIdentificationNumber: TaxIdHungarySchema.optional(),
    }),
]);

export const RestaurantQrSchema = z.object({
    restaurant: z.object({
        id: UuidSchema.nullable(),
        isReservationAllowed: z.boolean().nullable(),
        isTablePairedToRestaurant: z.boolean().nullable(),
        isTagPairedToTable: z.boolean().nullable(),
        restaurantImage: z.string().nullable(),
        restaurantLogo: z.string().nullable(),
        restaurantName: z.string().nullable(),
        tableTagMode: z.nativeEnum(TableTagMode).nullable(),
    }),
});
