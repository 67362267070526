import Box from '@mui/material/Box';
import * as React from 'react';

import { RestaurantMenuItems } from './RestaurantMenuItems';
import { RestaurantMenuNameAndDate } from './RestaurantMenuNameAndDate';

import type { APIv1RestaurantAdmin } from 'src/shared-interface/ApiRestaurantAdminInterface';

export const RestaurantMenuPermanent = (
    {
        permanentMenu,
    }: {
        permanentMenu: APIv1RestaurantAdmin['ENDPOINT']['GET:/menu/menu-data-for-pdf']['result']['result']['permanentMenuData'];
    }) => {

    return (

        <Box>
            {permanentMenu?.map((permanentMenu, index) => {
                return (
                    <Box key={index}>
                        <RestaurantMenuNameAndDate
                            description={permanentMenu?.description}
                            name={permanentMenu?.name}
                            openFrom={permanentMenu?.openFrom}
                            openTo={permanentMenu?.openTo}
                        />
                        <RestaurantMenuItems permanentMenuItems={permanentMenu.items} />
                    </Box>
                );
            })}

        </Box>

    );
};
