/**
 * Implement this interface to control how this object will be logged by the logger module.
 */
export interface LoggerStringifyable {
    /**
     * @returns Human readable string representation of this object for logger.
     */
    [toLoggerString](): string;
}

export const toLoggerString = Symbol('toLoggerString');

/**
 * Decides if the passed param is instance of LoggerStringifyable.
 * @param x The param which needs to be identified.
 * @returns Returns if the passed param is instanceof LoggerStringifyable.
 */
export const isLoggerStringifyable = (x: unknown): x is LoggerStringifyable => {
    return !!(
        x &&
        (typeof (x as any)[toLoggerString] === 'function')
    );
};
