import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ForkLiftIcon } from 'common/components/icons/ForkLiftIcon';
import SVGIcon from 'restaurant/public/svg/background.svg';

import type { RestaurantQrSchema } from 'src/shared/restaurantTypes';
import type { z } from 'zod';
import type { ReactNode } from 'react';

export const RestaurantLayout = ({
    children,
    restaurant,
}: {
    children: ReactNode;
    restaurant: z.infer<typeof RestaurantQrSchema>['restaurant'];
}) => {
    const { restaurantLogo, restaurantName, restaurantImage } = restaurant;

    return (
        <>
            <Box sx={{
                backgroundImage: `url('${restaurantImage ? restaurantImage : SVGIcon.src}')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderBottomLeftRadius: ['0', '0', '24px'],
                borderBottomRightRadius: ['0', '0', '24px'],
                height: '412px',
                width: '100%',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
                >
                    <Box sx={{
                        alignSelf: 'center',
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        boxSizing: 'border-box',
                        height: '86px',
                        marginTop: '72px',
                        width: '86px',
                    }}
                    >
                        <Box sx={{
                            alignItems: 'center',
                            borderRadius: '8px',
                            display: 'flex',
                            height: '86px',
                            justifyContent: 'center',
                            width: '86px',
                        }}
                        >
                            {!restaurantLogo && (

                                <Box sx={{ backgroundColor: '#F4F6F5', borderRadius: '8px', height: '63px', m: '12px 12px', width: '63px' }}>
                                    <ForkLiftIcon sx={{
                                        height: '60px',
                                        ml: '16px',
                                        mt: '1px',
                                        width: '30px',
                                    }}
                                    />
                                </Box>
                            )}
                            {restaurantLogo && (
                                <img alt="Restaurant logo" src={restaurantLogo} style={{ borderRadius: '16px', width: '86px' }} />
                            )}
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            alignSelf: 'center',
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 700,
                            textShadow: '0 0 10px #000000, 0 0 4px #000000',
                        }}
                        variant="subtitle1"
                    >
                        {restaurantName}
                    </Typography>
                    <Box sx={{ mt: '72px' }}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
