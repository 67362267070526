import React, { useContext } from 'react';
import hu from 'date-fns/locale/hu';
import de from 'date-fns/locale/de';
import sk from 'date-fns/locale/sk';
import cs from 'date-fns/locale/cs';
import en from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, formatDistance } from 'date-fns';
import { z } from 'zod';
import { deDE, enUS, huHU, skSK, csCZ } from '@mui/x-date-pickers/locales';

import type { TFunction } from 'i18next';

export enum Lang {
    cs = 'cs',
    en = 'en',
    sk = 'sk',
    de = 'de',
    hu = 'hu',
}

const dateLanguage = {
    cs, de, en, hu, sk,
};

export const detectDefaultLanguage = () => {
    if (typeof navigator !== 'undefined') {
        const supportedLanguages = Object.values(Lang);
        const languages = (navigator.languages ?? []).concat([(navigator.language ?? Lang.en)]);
        for (const language of languages) {
            const lang = language.split(/[:-]/)[0] as Lang;
            if ((supportedLanguages as string[]).includes(lang)) {
                return Lang[lang];
            }
        }
    }
    return Lang.en;
};

export const LangSchema = z.nativeEnum(Lang);

interface LocalizationContextData {
    lang: Lang;
    setLang: (lang: Lang) => void;
}

const LocalizationContext = React.createContext<LocalizationContextData>({
    lang: Lang.en,
    setLang: () => undefined,
});

const localeTexts = {
    [Lang.cs]: csCZ.components.MuiLocalizationProvider.defaultProps.localeText,
    [Lang.en]: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    [Lang.de]: deDE.components.MuiLocalizationProvider.defaultProps.localeText,
    [Lang.hu]: huHU.components.MuiLocalizationProvider.defaultProps.localeText,
    [Lang.sk]: skSK.components.MuiLocalizationProvider.defaultProps.localeText,
} satisfies Record<Lang, typeof enUS.components.MuiLocalizationProvider.defaultProps.localeText>;

export const LocalizationContextProvider = ({ lang, setLang, children }: {
    lang: Lang;
    setLang: (lang: Lang) => void;
    children: React.ComponentElement<any, any>;
}) => {
    return (
        <LocalizationContext.Provider value={{ lang, setLang }}>
            <LocalizationProvider adapterLocale={dateLanguage[lang]} dateAdapter={AdapterDateFns} localeText={localeTexts[lang]}>
                {children}
            </LocalizationProvider>
        </LocalizationContext.Provider>
    );
};

export const useMessages = <T extends Record<Lang, Record<string, unknown>>>(messages: T, lang?: Lang): T[Lang.en] => {
    const { lang: defaultLang } = useContext(LocalizationContext);

    return messages[lang ?? defaultLang];
};

/**
 * @deprecated
 */
export const useFormatDate = (defaultPattern = 'do MMM yyyy'): (date: Date, pattern?: string) => string => {
    const { lang } = useContext(LocalizationContext);
    return (date: Date, pattern: string = defaultPattern) => format(date, pattern, { locale: dateLanguage[lang] });
};

export const useFormatDistance = (): (date1: Date, date2: Date) => string => {
    const { lang } = useContext(LocalizationContext);
    return (date: Date, date2: Date) => formatDistance(date, date2, { locale: dateLanguage[lang] });
};

export const useFormatDateWithTime = (defaultPattern = 'Pp'): (date: Date, pattern?: string) => string => {
    const { lang } = useContext(LocalizationContext);
    return (date: Date, pattern: string = defaultPattern) => format(date, pattern, { locale: dateLanguage[lang] });
};

export const useFormatTime = (defaultPattern = 'p'): (date: Date, pattern?: string) => string => {
    const { lang } = useContext(LocalizationContext);
    return (date: Date, pattern: string = defaultPattern) => format(date, pattern, { locale: dateLanguage[lang] });
};

export const useLanguageContext = (): LocalizationContextData => {
    return useContext(LocalizationContext);
};

export const CURRENCY = (t: TFunction, cur: string): string => {
    return t(cur);
};
