import MuiLoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
import { useCallback, useState } from 'react';

import type Button from '@mui/material/Button';

type LoadingButtonProps = {
    loading?: boolean;
    buttonProps?: Parameters<typeof Button>[0];
    children?: React.ReactNode;
};

export const LoadingButton = ({ buttonProps = {}, loading = false, children = null }: LoadingButtonProps) => {
    return (
        <MuiLoadingButton
            {...buttonProps}
            disabled={buttonProps.disabled}
            loading={loading}
        >
            {children}
        </MuiLoadingButton>
    );
};

export const OnClickButton = (props: Omit<LoadingButtonProps, 'loading'> & {
    onClick: () => unknown;
}) => {
    const [ loading, setLoading ] = useState(false);

    const callback = useCallback(async () => {
        const onClick = props.onClick;
        setLoading(true);
        await onClick();
        setLoading(false);
    }, [props.onClick]);

    return (
        <LoadingButton
            {...props}
            buttonProps={{
                ...props.buttonProps,
                disabled: (props.buttonProps && props.buttonProps.disabled) || loading,
                onClick: callback,
            }}
            loading={loading}
        />
    );
};
