import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { format, parse } from 'date-fns';

import type { NonEmptyString, TimeString } from 'src/shared/types';

export const RestaurantMenuNameAndDate = (
    {
        description,
        name,
        openFrom,
        openTo,
    }: {
        description: NonEmptyString | null;
        name: NonEmptyString;
        openFrom: TimeString;
        openTo: TimeString;
    }) => {
    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '2rem',
                }}
            >
                <Typography component="h2" variant="h5">
                    {name}
                </Typography>
                <Typography component="span" sx={{ fontSize: '1rem', ml: '1rem' }}>
                ({format(parse(`${openFrom}`, 'HH:mm:ss', new Date()), 'HH:mm')} - {format(parse(`${openTo}`, 'HH:mm:ss', new Date()), 'HH:mm')})
                </Typography>
            </Box>
            <Box sx={{
                color: 'dimgrey',
                fontSize: '.90rem',
                mb: '2rem',
            }}
            >{description}</Box>
        </>
    );

};
