import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OnClickButton } from 'common/components/LoadingButton';

import type { ReservationDataCreateSchema } from './types';
import type { FormikType } from 'src/form-utils';
import type { z } from 'zod';

export const ReservationBoxStep2 = (
    {
        formik,
    }: {
        formik: FormikType<z.infer<typeof ReservationDataCreateSchema>>;
    }
) => {
    const { t } = useTranslation('restaurant.reservation');
    const inputName = formik.stringNotNullInputProps('user.name');
    const inputPhone = formik.stringNotNullInputProps('user.phone');
    const inputEmail = formik.stringNotNullInputProps('user.email');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>

            <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t('input.label.name')}
                size="small"
                {...inputName}
                helperText={inputName.error ? t('input.error.name') : null}
            />

            <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t('input.label.email')}
                size="small"
                {...formik.stringNotNullInputProps('user.email')}
                helperText={inputEmail.error ? t('input.error.email') : null}
            />

            <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={t('input.label.phone')}
                placeholder="+420 602 333 222"
                size="small"
                {...formik.stringNotNullInputProps('user.phone')}
                helperText={inputPhone.error ? t('input.error.phone') : null}
            />

            <TextField
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
                label={t('input.label.note')}
                multiline
                placeholder={t('input.placeholder.notes')}
                sx={{ textarea: { height: '60px !important' }, width: '100%' }}
                variant="outlined"
                {...formik.stringNotNullInputProps('note')}
                // next-line fix issue 1. click on field (no-edit) 2. blur and save (appear message field is not NonEmptyString)
                onBlur={() => {}}
            />

            <OnClickButton
                buttonProps={{
                    variant: 'contained',
                }}
                onClick={() => formik.submitForm()}
            >
                {t('button.makeReservation')}
            </OnClickButton>

            <Typography paragraph sx={{ color: 'black', fontSize: '0.7rem' }}>
                {t('license.firstPart')}
                {' '}
                <Link href="https://qerko.com/terms/general-terms/" rel="noopener" sx={{ textDecoration: 'none' }} target="_blank">{t('license.termsAndCondition')}</Link>
                {' '}
                {t('license.and')}
                {' '}
                <Link href="https://qerko.com/terms/general-privacy-policy/" rel="noopener" sx={{ textDecoration: 'none' }} target="_blank">{t('license.personalDataProtection')}</Link>.
            </Typography>

        </Box>
    );
};
