import type { NonEmptyString, NonEmptyString255, Email, Phone, Uuid, DateString, NonNegativeInteger, PositiveInteger, NonNegativeFloat, DateTimeString, TimeString, PreferredCurrency } from '../shared/types';

export interface APIv1Qr {
    'ENDPOINT': {
        'GET:/restaurant/by-id/:restaurantId': {
            input: {
                params: {
                    restaurantId: string;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/restaurant/by-table-tag/:id/:namespace': {
            input: {
                params: {
                    id: string;
                    namespace: string;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/restaurant/by-eshop-order/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        restaurant: {
                            id: Uuid | null;
                            isReservationAllowed: boolean | null;
                            isTablePairedToRestaurant: boolean | null;
                            isTagPairedToTable: boolean | null;
                            restaurantImage: string | null;
                            restaurantLogo: string | null;
                            restaurantName: string | null;
                            tableTagMode: TableTagMode | null;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/reservation/create': {
            input: {
                data: {
                    restaurantId: Uuid;
                    slotId: NonEmptyString;
                    seats: PositiveInteger;
                    captchaVerificationToken: NonEmptyString;
                    user: {
                        name: NonEmptyString255;
                        phone: NonEmptyString;
                        email: Email;
                    };
                    note: NonEmptyString255 | null;
                    reservationSubSource?: ReservationSubSource | null | undefined;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        reservation: {
                            id: Uuid;
                            status: ReservationIipClientStatus;
                            rejectedReason: NonEmptyString | null;
                            reservationTime: DateTimeString | null;
                            seats: NonNegativeInteger;
                            user: {
                                name: NonEmptyString;
                                phone: Phone;
                                email: Email;
                            };
                            note: NonEmptyString | null;
                            restaurant: {
                                id: Uuid;
                                address: string;
                                name: NonEmptyString;
                                phone: Phone | null;
                                coordinates: {
                                    latitude: NonNegativeFloat;
                                    longitude: NonNegativeFloat;
                                };
                            };
                        };
                    };
                } | {
                    httpStatus: 403;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/reservation/slots': {
            input: {
                query: {
                    restaurantId: Uuid;
                    date: DateString;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    note: NonEmptyString | null;
                    slots: Array<{
                        id: NonEmptyString;
                        start: TimeString;
                        end: TimeString;
                        seats: NonNegativeInteger;
                        note: NonEmptyString | null;
                    }>;
                };
            };
        };
        'GET:/reservation/config': {
            input: {
                query: {
                    restaurantId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    reservationDateInterval: {
                        start: DateString;
                        end: DateString;
                    };
                };
            };
        };
        'POST:/reservation/cancel': {
            input: {
                data: {
                    reservationId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    reservation: {
                        id: Uuid;
                        status: ReservationIipClientStatus;
                        rejectedReason: NonEmptyString | null;
                        reservationTime: DateTimeString | null;
                        seats: NonNegativeInteger;
                        user: {
                            name: NonEmptyString;
                            phone: Phone;
                            email: Email;
                        };
                        note: NonEmptyString | null;
                        restaurant: {
                            id: Uuid;
                            address: string;
                            name: NonEmptyString;
                            phone: Phone | null;
                            coordinates: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            };
                        };
                    };
                };
            };
        };
        'GET:/reservation/retrieve': {
            input: {
                query: {
                    reservationId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    reservation: {
                        id: Uuid;
                        status: ReservationIipClientStatus;
                        rejectedReason: NonEmptyString | null;
                        reservationTime: DateTimeString | null;
                        seats: NonNegativeInteger;
                        user: {
                            name: NonEmptyString;
                            phone: Phone;
                            email: Email;
                        };
                        note: NonEmptyString | null;
                        restaurant: {
                            id: Uuid;
                            address: string;
                            name: NonEmptyString;
                            phone: Phone | null;
                            coordinates: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            };
                        };
                    };
                };
            };
        };
        'GET:/menu/menu-data-for-pdf': {
            input: {
                query: {
                    restaurantId: Uuid;
                    from?: DateString | null | undefined;
                    to?: DateString | null | undefined;
                    usePermanentMenu?: NonEmptyString | undefined;
                    useDailyMenu?: NonEmptyString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenuData: Array<{
                        data: Array<{
                            description: NonEmptyString | null;
                            id: Uuid;
                            items: Array<{
                                description: NonEmptyString | null;
                                currency: PreferredCurrency;
                                id: Uuid;
                                images: Array<string>;
                                name: string;
                                price: string;
                                toTable: boolean;
                                toBill: boolean;
                            }>;
                            name: NonEmptyString;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        date: Date;
                    }>;
                    permanentMenuData: Array<{
                        description: NonEmptyString | null;
                        id: Uuid;
                        items: Array<{
                            description: NonEmptyString | null;
                            currency: PreferredCurrency;
                            id: Uuid;
                            images: Array<string>;
                            name: string;
                            price: string;
                            toTable: boolean;
                            toBill: boolean;
                        }>;
                        name: NonEmptyString;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                };
            };
        };
    };
    'SUBSCRIPTION': {
    };
}

export enum TableTagMode {
    STATIC_TABLE = 'STATIC_TABLE',
    DIRECT_BILL = 'DIRECT_BILL',
}

export enum ReservationIipClientStatus {
    Waiting = 'waiting',
    Confirmed = 'confirmed',
    Rejected = 'rejected',
    Canceled = 'canceled',
}

export enum ReservationSubSource {
    Seznam = 'seznam',
    Google = 'google',
}
