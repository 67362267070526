import * as Sentry from '@sentry/nextjs';
import { QerkoError } from 'src/shared/errors';

export const logger = {
    debug: (...props: unknown[]) => {
        // eslint-disable-next-line no-console,no-restricted-globals
        console.debug(...props);
    },

    error: (e: Error) => {
        // eslint-disable-next-line no-console,no-restricted-globals
        console.error(e, e instanceof QerkoError ? e.getExtra() : undefined);

        const extra = e instanceof QerkoError ? e.getExtra() : {};
        Object.entries(extra).forEach(([ key, value ]) => {
            extra[key] = JSON.stringify(value, null, 4);
        });

        Sentry.captureException(e, {
            extra,
        });
    },
};
