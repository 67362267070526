import shortUuid from 'short-uuid';
import { z } from 'zod';
import { extendApi as rawExtendedApi } from '@anatine/zod-openapi';
import { Brand, Either } from 'effect';

import BigNumber from './bigNumber';

import type { ZodTypeDef } from 'zod/lib/types';
import type { BigNumberType } from './bigNumber';

const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateTimeRegex = /^\d{4}-\d{2}-\d{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
const numericRegex = /^-?[0-9]+(\.[0-9]+)?$/;
const zipCodeRegex = /^[0-9]{1,5}$/;
export const jsonDateTimeRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]+Z$/;

export enum Country {
    CZ = 'CZ',
    SK = 'SK',
    DE = 'DE',
    AT = 'AT',
    HU = 'HU',
}

abstract class UuidClass {
    abstract __uuidTag: true;

    abstract toShortUuid(): ShortUuid;
}

// @ts-expect-error
String.prototype.toShortUuid = function (): ShortUuid {
    return shortUuid().fromUUID(this as string) as ShortUuid;
};

abstract class MillisecondsClass {
    abstract __millisecondsTag: true;

    abstract toSeconds(): Seconds;
}

// @ts-expect-error
Number.prototype.toSeconds = function (): Seconds {
    return Math.round(this as number / 1000) as Seconds;
};

abstract class SecondsClass {
    abstract __secondsTag: true;

    abstract toMilliseconds(): Milliseconds;
}

// @ts-expect-error
Number.prototype.toMilliseconds = function (): Milliseconds {
    return Math.round(this as number * 1000) as Milliseconds;
};

export const extendApi: typeof rawExtendedApi = (schema, schemaObject) => {
    const result = rawExtendedApi(schema, schemaObject);
    if ('regex' in schema) {
        // @ts-expect-error
        result.regex = schema.regex;
    }

    return result;
};

export const CreateJsonStringSchema = <TSchema extends z.Schema>(schema: TSchema): z.Schema<z.output<TSchema>, z.ZodTypeDef, string> => {
    const result = z.string().transform((value, ctx) => {
        let result: unknown = null;
        try {
            result = JSON.parse(value);
        } catch (e: unknown) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid JSON string',
            });
            return value;
        }

        const parsedResult = schema.safeParse(result);
        if (!parsedResult.success) {
            for (const error of parsedResult.error.errors) {
                ctx.addIssue(error);
            }
            return value;
        }

        return parsedResult.data;
    });
    // @ts-expect-error
    result.__nestedJsonSchema = schema;

    return result;
};

export type QerkoBrand<ParentType, Name extends string> = ParentType & Brand.Brand<Name>;

export const createIs = <T>(schema: z.ZodSchema<T, any, any>) => {
    return {
        is: (value: unknown): value is T => {
            return schema.safeParse(value).success;
        },
    };
};

export interface EnumLike { [k: string]: string }

const useCache = <Key, Value>(factory: (key: Key) => Value): (key: Key) => Value => {
    const cache = new Map<Key, Value>();
    return (key: Key) => {
        let value = cache.get(key);
        if (value === undefined) {
            value = factory(key);
            cache.set(key, value);
        }

        return value;
    };
};

export const createEnumIs = useCache(<T extends EnumLike>(values: T) => createIs(z.nativeEnum(values)));

// eslint-disable-next-line @typescript-eslint/naming-convention
export const createRegexSchema = <T extends string>({
    regex,
    message,
    transform,
    refinement,
}: {
    regex: RegExp;
    message?: string;
    transform?: (value: string) => string;
    refinement?: (value: string) => boolean;
}): SchemaWithRegex<T> => {
    const errorMessage = message ?? `Value must be in ${regex} format`;
    if (transform === undefined && refinement === undefined) {
        const schema = z
            .string({ message: errorMessage })
            .regex(regex, { message: errorMessage }) as unknown as SchemaWithRegex<T>;
        schema.regex = regex;
        return schema;
    }

    const schema = z
        .string({ message: errorMessage })
        .transform((value, ctx) => {
            const result = transform ? transform(value) : value;
            if (refinement !== undefined && !refinement(result)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: errorMessage,
                });
                return z.NEVER;
            }

            if (!regex.test(result)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: errorMessage,
                });
                return z.NEVER;
            }

            return result;
        }) as unknown as SchemaWithRegex<T>;

    schema.regex = regex;
    return schema;
};

type SchemaWithRegex<T, Input = string> = z.ZodType<T, ZodTypeDef, Input> & { regex: RegExp };
type Schema<T, Input = T> = z.ZodType<T, ZodTypeDef, Input>;

// numbers
export type Integer = QerkoBrand<number, 'Integer'>;
export type NonZero = QerkoBrand<number, 'NonZero'>;
export type NonPositive = QerkoBrand<number, 'NonPositive'>;
export type NonNegative = QerkoBrand<number, 'NonNegative'>;

export const Integer = Brand.refined<Integer>(
    (value): value is Integer => Number.isInteger(value),
    () => Brand.error('Value must be integer'),
);

export const NonZero = Brand.refined<NonZero>(
    (value): value is NonZero => value !== 0,
    () => Brand.error('Value must be non-zero'),
);

export const NonPositive = Brand.refined<NonPositive>(
    (value): value is NonPositive => value <= 0,
    () => Brand.error('Value must be non-positive'),
);

export const NonNegative = Brand.refined<NonNegative>(
    (value): value is NonNegative => value >= 0,
    () => Brand.error('Value must be non-negative'),
);

// eslint-disable-next-line @typescript-eslint/naming-convention
const createNumberBasedSchema = <T extends Brand.Brand<any>>(brand: Brand.Brand.Constructor<T>): z.ZodSchema<T, any, number> => {
    // @ts-expect-error
    return z.custom().transform<T>((value: Brand.Brand.Unbranded<T>, ctx) => {
        const result = brand.either(value);
        if (Either.isLeft(result)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: result.left.map((error) => error.message).join('; '),
            });
            return z.NEVER;
        }

        return result.right;
    });
};

export const IntegerSchema: z.ZodType<Integer, any, number> = extendApi(createNumberBasedSchema(Integer), {
    type: ['number'],
    format: 'Integer',
});

export const NonNegativeInteger = Brand.all(Integer, NonNegative);
export type NonNegativeInteger = Brand.Brand.FromConstructor<typeof NonNegativeInteger>;
export const NonNegativeIntegerSchema: z.ZodType<NonNegativeInteger, any, number> = extendApi(createNumberBasedSchema(NonNegativeInteger), {
    type: ['number'],
    format: 'NonNegativeInteger',
});

export const PositiveInteger = Brand.all(Integer, NonNegative, NonZero);
export type PositiveInteger = Brand.Brand.FromConstructor<typeof PositiveInteger>;
export const PositiveIntegerSchema: z.ZodType<PositiveInteger, any, number> = extendApi(createNumberBasedSchema(PositiveInteger), {
    type: ['number'],
    format: 'PositiveInteger',
});

export type Milliseconds = QerkoBrand<Integer & MillisecondsClass, 'Milliseconds'>;
export const MillisecondsSchema: z.ZodType<Milliseconds, any, number> = extendApi(NonNegativeIntegerSchema.transform((value) => value as unknown as Milliseconds), {
    type: ['number'],
    format: 'Milliseconds',
});

export type MillisecondsString = QerkoBrand<string, 'IntegerString'>;
export const MillisecondsStringSchema: SchemaWithRegex<MillisecondsString> = createRegexSchema<MillisecondsString>({ regex: /^(0|[1-9][0-9]*)$/, message: 'Value must be milliseconds string' });

export type Seconds = QerkoBrand<number & SecondsClass, 'Seconds'>;
export const SecondsSchema: z.ZodType<Seconds, any, number> = NonNegativeIntegerSchema.transform((value) => value as unknown as Seconds);

export type Minutes = QerkoBrand<number, 'Minutes'>;
export const MinutesSchema: z.ZodType<Minutes, any, number> = NonNegativeIntegerSchema.transform((value) => value as unknown as Minutes);

export type IntegerString = QerkoBrand<NonEmptyString255, 'IntegerString'>;
const integerStringRegex = /^-?(0|[1-9][0-9]*)$/;
export const IntegerStringSchema: SchemaWithRegex<IntegerString> = extendApi(createRegexSchema<IntegerString>({ regex: integerStringRegex, message: 'Value must be integer string' }), {
    type: ['string'],
    format: 'Integer as string',
    pattern: integerStringRegex.source,
});

export type NonNegativeIntegerString = QerkoBrand<IntegerString, 'NonNegativeIntegerString'>;
const nonNegativeIntegerStringRegex = /^(0|[1-9][0-9]*)$/;
export const NonNegativeIntegerStringSchema: SchemaWithRegex<NonNegativeIntegerString> = extendApi(createRegexSchema<NonNegativeIntegerString>({ regex: nonNegativeIntegerStringRegex, message: 'Value must be non-negative-integer string' }), {
    type: ['string'],
    format: 'NonNegativeInteger',
    pattern: nonNegativeIntegerStringRegex.source,
});

export type PositiveIntegerString = QerkoBrand<NonNegativeIntegerString, 'PositiveIntegerString'>;
const positiveIntegerStringRegex = /^[1-9][0-9]*$/;
export const PositiveIntegerStringSchema: SchemaWithRegex<PositiveIntegerString> = extendApi(createRegexSchema<PositiveIntegerString>({ regex: positiveIntegerStringRegex, message: 'Value must be positive-integer string' }), {
    type: ['string'],
    format: 'PositiveInteger',
    pattern: positiveIntegerStringRegex.source,
});

export const IntegerFromStringSchema: z.ZodSchema<Integer, z.ZodTypeDef, string> = extendApi(IntegerStringSchema.transform((value) => parseInt(value, 10)).pipe(IntegerSchema), {
    type: ['string'],
    format: 'Integer as string',
    pattern: integerStringRegex.source,
});
export const NonNegativeIntegerFromStringSchema: z.ZodSchema<NonNegativeInteger, z.ZodTypeDef, string> = extendApi(NonNegativeIntegerStringSchema.transform((value) => parseInt(value, 10)).pipe(NonNegativeIntegerSchema), {
    type: ['string'],
    format: 'NonNegativeInteger',
    pattern: nonNegativeIntegerStringRegex.source,
});
export const PositiveIntegerFromStringSchema: z.ZodSchema<PositiveInteger, z.ZodTypeDef, string> = extendApi(PositiveIntegerStringSchema.transform((value) => parseInt(value, 10)).pipe(PositiveIntegerSchema), {
    type: ['string'],
    format: 'PositiveInteger',
    pattern: positiveIntegerStringRegex.source,
});
export const MillisecondsFromStringSchema: z.ZodSchema<Milliseconds, z.ZodTypeDef, string> = extendApi(MillisecondsStringSchema.transform((value) => parseInt(value, 10)).pipe(MillisecondsSchema), {
    type: ['string'],
    format: 'Milliseconds',
    pattern: nonNegativeIntegerStringRegex.source,
});

export type Float = QerkoBrand<number, 'Float'>;
export type FloatString = QerkoBrand<NonEmptyString255, 'FloatString'>;
export const FloatSchema: z.ZodType<Float, z.ZodTypeDef, number> = z.number({ message: 'Value must be float' }).refinement<Float>((value): value is Float => Number.isFinite(value), { message: 'Value must be float', code: 'custom' });
export const FloatStringSchema: SchemaWithRegex<FloatString> = extendApi(createRegexSchema<FloatString>({ regex: numericRegex, message: 'Value must be float string' }), {
    type: ['string'],
    format: 'Float as string',
    pattern: numericRegex.source,
});
export const FloatFromStringSchema: z.ZodSchema<Float, z.ZodTypeDef, string> = extendApi(FloatStringSchema.transform((value) => parseFloat(value)).pipe(FloatSchema), {
    type: ['string'],
    format: 'Float as string',
    pattern: integerStringRegex.source,
});

export type JavascriptDayNumber = (0 | 1 | 2 | 3 | 4 | 5 | 6) & Integer;
export const JavascriptDayNumberSchema: z.ZodType<JavascriptDayNumber, any, number> = z.intersection(
    z.union([
        z.literal(0),
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
    ]),
    IntegerSchema,
    { invalid_type_error: 'Value must be integer and in interval <0,6>' },
);

export type PositiveFloat = QerkoBrand<Float, 'PositiveFloat'>;
export const PositiveFloatSchema: z.ZodType<PositiveFloat, z.ZodTypeDef, number> = extendApi(FloatSchema.refinement<PositiveFloat>((value): value is PositiveFloat => value > 0, { message: 'Value must be positive float', code: 'custom' }), {
    type: ['number'],
    format: 'PositiveFloat',
});

export type NonNegativeFloat = QerkoBrand<Float, 'NonNegativeFloat'>;
export const NonNegativeFloatSchema: z.ZodType<NonNegativeFloat, z.ZodTypeDef, number> = extendApi(FloatSchema.refinement<NonNegativeFloat>((value): value is NonNegativeFloat => value >= 0, { message: 'Value must be non-negative float', code: 'custom' }), {
    type: ['number'],
    format: 'NonNegativeFloat',
});

// strings
export type NonEmptyString = QerkoBrand<string, 'NonEmptyString'>;
export const NonEmptyStringSchema: z.ZodType<NonEmptyString, z.ZodTypeDef, string> = extendApi(z.string().refinement<NonEmptyString>((value): value is NonEmptyString => value.trim() !== '', { message: 'Value must be non empty string', code: 'custom' }), {
    type: ['string'],
    format: 'NonEmpty',
    pattern: '^.+$',
    minLength: 1,
});

export type NonEmptyString512 = QerkoBrand<NonEmptyString, 'NonEmptyString512'>;
export const NonEmptyString512Schema: z.ZodType<NonEmptyString512, any, string> = extendApi(NonEmptyStringSchema.refinement<NonEmptyString512>((value): value is NonEmptyString512 => value.length <= 512, { message: 'Value must be non empty string and up to 512 characters', code: 'custom' }), {
    type: ['string'],
    format: 'NonEmpty',
    pattern: '^.{1,512}$',
    minLength: 1,
    maxLength: 512,
});

export type NonEmptyString255 = QerkoBrand<NonEmptyString, 'NonEmptyString255'>;
export const NonEmptyString255Schema: z.ZodType<NonEmptyString255, any, string> = extendApi(NonEmptyStringSchema.refinement<NonEmptyString255>((value): value is NonEmptyString255 => value.length <= 255, { message: 'Value must be non empty string and up to 255 characters', code: 'custom' }), {
    type: ['string'],
    format: 'NonEmpty',
    pattern: '^.{1,255}$',
    minLength: 1,
    maxLength: 255,
});

export type NonEmptyString128 = QerkoBrand<NonEmptyString255, 'NonEmptyString128'>;
export const NonEmptyString128Schema: z.ZodType<NonEmptyString128, any, string> = extendApi(NonEmptyStringSchema.refinement<NonEmptyString128>((value): value is NonEmptyString128 => value.length <= 128, { message: 'Value must be non empty string and up to 128 characters', code: 'custom' }), {
    type: ['string'],
    format: 'NonEmpty',
    pattern: '^.{1,128}$',
    minLength: 1,
    maxLength: 128,
});

export type NonEmptyString64 = QerkoBrand<NonEmptyString128, 'NonEmptyString64'>;
export const NonEmptyString64Schema: z.ZodType<NonEmptyString64, any, string> = NonEmptyStringSchema.refinement<NonEmptyString64>((value): value is NonEmptyString64 => value.length <= 64, { message: 'Value must be non empty string and up to 64 characters', code: 'custom' });

export type NonEmptyString32 = QerkoBrand<NonEmptyString64, 'NonEmptyString32'>;
export const NonEmptyString32Schema: z.ZodType<NonEmptyString32, any, string> = NonEmptyStringSchema.refinement<NonEmptyString32>((value): value is NonEmptyString32 => value.length <= 32, { message: 'Value must be non empty string and up to 32 characters', code: 'custom' });

export type NonEmptyString16 = QerkoBrand<NonEmptyString32, 'NonEmptyString16'>;
export const NonEmptyString16Schema: z.ZodType<NonEmptyString16, any, string> = NonEmptyStringSchema.refinement<NonEmptyString16>((value): value is NonEmptyString16 => value.length <= 16, { message: 'Value must be non empty string and up to 16 characters', code: 'custom' });

export type NonEmptyString8 = QerkoBrand<NonEmptyString16, 'NonEmptyString8'>;
export const NonEmptyString8Schema: z.ZodType<NonEmptyString8, any, string> = NonEmptyStringSchema.refinement<NonEmptyString8>((value): value is NonEmptyString8 => value.length <= 8, { message: 'Value must be non empty string and up to 8 characters', code: 'custom' });

export type Uuid = QerkoBrand<NonEmptyString64 & UuidClass & shortUuid.UUID, 'Uuid'>;
export const UuidSchema: SchemaWithRegex<Uuid> = extendApi(createRegexSchema({ message: 'Value must be in UUID format', regex: uuidRegex }), {
    type: ['string'],
    format: 'Uuid',
    pattern: uuidRegex.source,
    example: '5455d25b-415c-4104-9035-5c357e60ff01',
});
export const UuidBrand = {
    regex: uuidRegex,
    createFromUuidOrShortUuid: (data: string): Uuid => {
        if (createIs(UuidSchema).is(data)) {
            return data;
        }

        if (createIs(ShortUuidSchema).is(data)) {
            return shortUuid().toUUID(data) as Uuid;
        }

        throw new Error('String must be in UUID or short UUID format');
    },
};

export const UuidFromShortUuidOrUuidSchema: z.ZodSchema<Uuid, z.ZodTypeDef, string> = z.string().transform((value, crx) => {
    try {
        return UuidBrand.createFromUuidOrShortUuid(value);
    } catch (err: unknown) {
        if (!(err instanceof Error)) {
            throw err;
        }

        crx.addIssue({
            code: z.ZodIssueCode.custom,
            message: err.message,
        });
        return z.NEVER;
    }
});

type ShortUuid = QerkoBrand<NonEmptyString64 & shortUuid.SUUID, 'ShortUuid'>;
export const ShortUuidSchema: SchemaWithRegex<ShortUuid> = createRegexSchema<ShortUuid>({ regex: new RegExp('^[123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ]{22}$'), message: 'Value must be short short-Uuid' });

export type S3Bucket = QerkoBrand<NonEmptyString, 'S3Bucket'>;
export const S3BucketSchema: SchemaWithRegex<S3Bucket> = createRegexSchema<S3Bucket>({ regex: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/, message: 'Value must be in s3 bucket format' });

export type S3Key = QerkoBrand<NonEmptyString, 'S3Bucket'>;
export const S3KeySchema: SchemaWithRegex<S3Key> = createRegexSchema<S3Key>({ regex: /^[a-zA-Z0-9!-_.*'()čČěĚšŠřŘžŽýÝáÁíÍéÉóÓúÚůŮ /]+$/u, message: 'Value must be in s3 key format' });

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export type Email = QerkoBrand<NonEmptyString255, 'Email'>;
export const EmailSchema: SchemaWithRegex<Email> = extendApi(createRegexSchema<Email>({ regex: emailRegex, message: 'Value must be in email format' }), {
    type: ['string'],
    format: 'Email',
    pattern: emailRegex.source,
});

export type PrivateAppleEmail = QerkoBrand<Email, 'PrivateAppleEmail'>;
export const PrivateAppleEmailSchema: SchemaWithRegex<PrivateAppleEmail> = createRegexSchema<PrivateAppleEmail>({ regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@privaterelay.appleid.com$/, message: 'Value must be in private-apple-email format' });

export type DomainWithLimitedSymbols = QerkoBrand<NonEmptyString64, 'DomainWithLimitedSymbols'>;
export const DomainWithLimitedSymbolsSchema: SchemaWithRegex<DomainWithLimitedSymbols> = createRegexSchema<DomainWithLimitedSymbols>({ regex: /^[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]+$/, message: 'Value must be in DomainWithLimitedSymbols format' });

export type Url = QerkoBrand<NonEmptyString255, 'Url'>;
export const UrlSchema: z.ZodType<Url, z.ZodTypeDef, string> = z.string().refinement((value): value is Url => value === value.trim() && z.string().url().safeParse(value).success, { message: 'Value must be in URL format', code: 'custom' });

export type HttpUrl = QerkoBrand<Url, 'HttpUrl'>;
export const HttpUrlSchema: z.ZodType<HttpUrl, z.ZodTypeDef, string> = UrlSchema.refinement((value): value is HttpUrl => /^https?:\/\//.test(value), { message: 'Value must be in http URL format', code: 'custom' });
export const HttpUrlFromUserInputSchema: z.ZodType<HttpUrl, z.ZodTypeDef, string> = z.string()
    .transform((value) => value.trim())
    .transform((value) => !value.startsWith('https://') && !value.startsWith('http://') && !z.string().url().safeParse(value).success ? `https://${value}` : value)
    .refinement((value): value is Url => z.string().url().safeParse(value).success, { message: 'Value must be in URL format', code: 'custom' })
    .refinement((value): value is HttpUrl => /^https?:\/\//.test(value), { message: 'Value must be in http URL format', code: 'custom' });

export type Phone = QerkoBrand<NonEmptyString, 'Phone'>;
const phoneRegex = /^\+\d{1,3}\d{3,15}$/;
export const PhoneSchema: SchemaWithRegex<Phone> = extendApi(createRegexSchema<Phone>({ regex: phoneRegex, message: 'Value must be in Phone format' }), {
    type: ['string'],
    format: 'Phone',
    pattern: phoneRegex.source,
});

export const normalizePhone = (phone: string): string => {
    phone = phone.replace(/[ .,\-_]/g, '');
    phone = phone.replace('++', '+');
    if (phone.length === 9 ) {
        phone = `+420${phone}`;
    }
    if (phone.substr(0, 2) === '00' ) {
        phone = `+${phone.substr(2)}`;
    }

    if ((phone.substr(0, 3) === '420' || phone.substr(0, 3) === '421') && phone.length === 12 ) {
        phone = `+${phone}`;
    }

    if ((phone.substr(0, 4) === '*420' || phone.substr(0, 4) === '*421')) {
        phone = phone.replace('*', '+');
    }

    return phone;
};

export const PhoneFromUnnormalizedStringSchema: z.Schema<Phone, z.ZodTypeDef, string> = NonEmptyStringSchema.transform((value, ctx) => {
    const normalizedPhone = normalizePhone(value);
    const result = PhoneSchema.safeParse(normalizedPhone);
    if (!result.success) {
        assertNotUndefined(result.error.issues[0]);
        ctx.addIssue(result.error.issues[0]);
        return '' as Phone;
    }

    return result.data;
});

export type HexColor = QerkoBrand<NonEmptyString, 'HexColor'>;
const hexColorRegex = /^#[0-9a-f]{6}$/;
export const HexColorSchema: SchemaWithRegex<HexColor> = extendApi(createRegexSchema<HexColor>({ regex: hexColorRegex, message: 'Value must be in #[0-9a-f]{6} format' }), {
    type: ['string'],
    format: 'HexColor',
    pattern: hexColorRegex.source,
});

export type HexColorWithAlpha = QerkoBrand<NonEmptyString, 'HexColorWithAlpha'>;
const hexColorWithAlphaRegex = /^#[0-9a-f]{8}$/;
export const HexColorWithAlphaSchema: SchemaWithRegex<HexColorWithAlpha> = extendApi(createRegexSchema<HexColorWithAlpha>({ regex: hexColorWithAlphaRegex, message: 'Value must be in #[0-9a-f]{8} format (#RRGGBBAA)' }), {
    type: ['string'],
    format: 'HexColorWithAlpha',
    pattern: hexColorWithAlphaRegex.source,
});

export type YearDashMonth = QerkoBrand<NonEmptyString, 'YYYYdashMM'>;
const yearDashMonthRegex = /^[0-9]{4}-[0-9]{2}$/;
export const YearDashMonthSchema: SchemaWithRegex<YearDashMonth> = extendApi(createRegexSchema<YearDashMonth>({ regex: yearDashMonthRegex, message: 'Value must be in yyyy-MM format' }), {
    type: ['string'],
    format: 'YearDashMonth',
    pattern: yearDashMonthRegex.source,
    example: '2023-02',
});

export type VATNumber = QerkoBrand<NonEmptyString, 'VATNumber'>;
const vatNumberRegexes = {
    [Country.CZ]: /^CZ[0-9]{8,10}$/,
    [Country.SK]: /^SK[0-9]{10}$/,
    [Country.HU]: /^HU[0-9]{8}$/,
    [Country.DE]: /^DE[0-9]{9}$/,
    [Country.AT]: /^ATU[0-9]{8}$/,
} satisfies Record<Country, RegExp>;
export const VATNumberSchema: SchemaWithRegex<VATNumber> = createRegexSchema<VATNumber>({ regex: new RegExp(Object.values(vatNumberRegexes).map((regex) => `(${regex.source})`).join('|')), message: 'Value must be in VAT ID format' });

export type VATId = QerkoBrand<NonEmptyString, 'VATId'>;
export const VATIdSchema: SchemaWithRegex<VATId> = createRegexSchema<VATId>({ regex: /^[U]?[0-9]{0,11}[1-9][0-9]{0,11}$/, message: 'Value must be in VAT ID format' });

export type TaxIdHungary = QerkoBrand<NonEmptyString32, 'TaxIdHungary'>;
export const TaxIdHungarySchema: SchemaWithRegex<TaxIdHungary> = createRegexSchema<TaxIdHungary>({ regex: /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/, message: 'Hungary tax id must be in xxxxxxxx-y-zz format' });

export type PreferredCurrency = QerkoBrand<NonEmptyString, 'PreferredCurrency'>;
export const PreferredCurrencySchema: SchemaWithRegex<PreferredCurrency> = createRegexSchema<PreferredCurrency>({ regex: /^[A-Z]{3}$/, message: 'Value must be in PreferredCurrency format' });

export type NfcSerialNumber = QerkoBrand<NonEmptyString255, 'NfcSerialNumber'>;
export const NfcSerialNumberSchema: SchemaWithRegex<NfcSerialNumber> = createRegexSchema<NfcSerialNumber>({ regex: /^[0-9a-f]{0,255}$/, transform: (value) => value.toLowerCase() });

// Table with iban length per country: https://www.iban.com/structure
export type Iban = QerkoBrand<NonEmptyString64, 'Iban'>;
export const IbanSchema: SchemaWithRegex<Iban> = createRegexSchema<Iban>({ regex: /^((CZ|SK)[0-9]{22}|DE[0-9]{20}|(AT|LT)[0-9]{18}|HU[0-9]{26}|NO[0-9]{13}|(?!CZ|SK|DE|AT|LT|HU|NO)[A-Z]{2}[0-9]{13,31})$/ });
export const IbanFromUserInputSchema: SchemaWithRegex<Iban> = createRegexSchema<Iban>({ regex: /^((CZ|SK)[0-9]{22}|DE[0-9]{20}|(AT|LT)[0-9]{18}|HU[0-9]{26}|NO[0-9]{13}|(?!CZ|SK|DE|AT|LT|HU|NO)[A-Z]{2}[0-9]{13,31})$/, transform: (value) => value.replace(/ /g, '') });

export type SwiftBic = QerkoBrand<NonEmptyString16, 'SwiftBic'>;
export const SwiftBicSchema: SchemaWithRegex<SwiftBic> = createRegexSchema<SwiftBic>({ regex: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/ });
export const SwiftBicFromUserInputSchema: SchemaWithRegex<SwiftBic> = createRegexSchema<SwiftBic>({ regex: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/, transform: (value) => value.replace(/ /g, '') });

// ISO 3166-1 alpha-2 code
export type UserCountry = QerkoBrand<NonEmptyString, 'UserCountry'>;
export const UserCountrySchema: SchemaWithRegex<UserCountry> = createRegexSchema<UserCountry>({ regex: /^[A-Z]{2}$/, message: 'Value must be in UserCountry format' });

// ISO 639-1
export type UserLanguage = QerkoBrand<NonEmptyString, 'UserLanguage'>;
export const UserLanguageSchema: SchemaWithRegex<UserLanguage> = createRegexSchema<UserLanguage>({ regex: /^[a-z]{2}$/, message: 'Value must be in Language ISO 639-1 format (en, cs ...)' });

export type ZipCode = QerkoBrand<string, 'ZipCode'>;
export const ZipCodeSchema: SchemaWithRegex<ZipCode> = createRegexSchema<ZipCode>({ regex: zipCodeRegex, message: 'Value must be in [0-9]{1-5} format' });

// numeric strings
export type DecimalString = QerkoBrand<NonEmptyString, 'DecimalString'>;
export const DecimalStringSchema: Schema<DecimalString, string> = extendApi(NonEmptyStringSchema.refinement<DecimalString>((x): x is DecimalString => createIs(NonEmptyStringSchema).is(x) && !(new BigNumber(x)).isNaN(), { message: 'Value must be decimal string', code: 'custom' }), {
    type: ['string'],
    format: 'Decimal',
});

export type NonNegativeDecimalString = QerkoBrand<DecimalString, 'NonNegativeDecimalString'>;
export const NonNegativeDecimalStringSchema: Schema<NonNegativeDecimalString, string> = extendApi(NonEmptyStringSchema.refinement<NonNegativeDecimalString>((x: unknown): x is NonNegativeDecimalString => {
    if (!createIs(NonEmptyStringSchema).is(x)) return false;
    const num = new BigNumber(x);
    if (num.isNaN()) return false;
    return num.isGreaterThanOrEqualTo(0);
}, { message: 'Value must be non-negative decimal string', code: 'custom' }), {
    type: ['string'],
    format: 'NonNegativeDecimal',
});

export type PositiveDecimalString = QerkoBrand<NonNegativeDecimalString, 'PositiveDecimalString'>;
export const PositiveDecimalStringSchema: Schema<PositiveDecimalString, string> = extendApi(NonEmptyStringSchema.refinement<PositiveDecimalString>((x: unknown): x is PositiveDecimalString => {
    if (!createIs(NonEmptyStringSchema).is(x)) return false;
    const num = new BigNumber(x);
    if (num.isNaN()) return false;
    return num.isGreaterThan(0);
}, { message: 'Value must be positive decimal string', code: 'custom' }), {
    type: ['string'],
    format: 'PositiveDecimal',
});

// date
export type DateString = QerkoBrand<NonEmptyString, 'DateString'>;
export const DateStringSchema: SchemaWithRegex<DateString> = extendApi(createRegexSchema<DateString>({ message: 'Value must be in yyyy-MM-dd format', regex: dateRegex, refinement: (x) => {
    const date = new Date(x);
    const dNum = date.getTime();
    if (!dNum && dNum !== 0) {
        return false;
    }
    return date.toISOString().slice(0, 10) === x;
},
}), {
    type: ['string'],
    format: 'Date',
    pattern: dateRegex.source,
});

export type TimeWithMillisecondsString = QerkoBrand<NonEmptyString, 'TimeWithMillisecondsString'>;
export const TimeWithMillisecondsStringSchema: SchemaWithRegex<TimeWithMillisecondsString> = createRegexSchema({ message: 'Value must be in HH:mm:ss(.SSS)? format', regex: /^[0-9]{1,2}:[0-9]{1,2}(:[0-9]{1,2}(\.[0-9]+)?)?$/ });

export type TimeString = QerkoBrand<NonEmptyString, 'TimeString'>;
const timeStringRegex = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/;
export const TimeStringSchema: SchemaWithRegex<TimeString> = extendApi(createRegexSchema<TimeString>({ regex: timeStringRegex, message: 'Value must be in HH:mm:ss format' }), {
    type: ['string'],
    format: 'Time',
    pattern: timeStringRegex.source,
});

export type TimeWithoutSecondsString = QerkoBrand<NonEmptyString, 'TimeWithoutSecondsString'>;
export const TimeWithoutSecondsStringSchema: SchemaWithRegex<TimeWithoutSecondsString> = createRegexSchema<TimeWithoutSecondsString>({ regex: /^[0-9]{2}:[0-9]{2}$/, message: 'Value must be in HH:mm format' });

export type DateTimeString = QerkoBrand<NonEmptyString, 'DateTimeString'>;
export const DateTimeStringSchema: SchemaWithRegex<DateTimeString> = extendApi(createRegexSchema<DateTimeString>({ regex: dateTimeRegex, message: 'Value must be in HH:mm:ss format' }), {
    type: ['string'],
    format: 'DateTime',
    pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$',
});

export type JsonDateTime = QerkoBrand<NonEmptyString, 'JsonDateTime'>;
export const JsonDateTimeSchema: SchemaWithRegex<JsonDateTime> = createRegexSchema<JsonDateTime>({ regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/ });

export const BooleanFromStringSchema: z.ZodSchema<boolean, z.ZodTypeDef, string> = extendApi(z.enum(['true', 'false']).transform((value) => value === 'true').pipe(z.boolean()), {
    type: ['string'],
    format: 'boolean',
    pattern: '^(true|false)$',
});
export const BooleanFromStringWeakSchema: z.ZodSchema<boolean, z.ZodTypeDef, string> = extendApi(z.enum(['true', 'false', '1', '0']).transform((value) => ['true', '1'].includes(value)).pipe(z.boolean()), {
    type: ['string'],
    format: 'boolean',
    pattern: '^(0|1|true|false)$',
});
export const BooleanFromIntegerSchema: z.ZodSchema<boolean, z.ZodTypeDef, number> = z.union([z.literal(1), z.literal(0)]).transform((value) => value === 1).pipe(z.boolean());

export const DateFromJsonStringSchema = extendApi(NonEmptyStringSchema.transform((value) => new Date(value)).pipe(z.date()), {
    type: ['string'],
    format: 'DateJson',
});

const LiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);

type JsonObject = {[Key in string]: JsonValue} & {[Key in string]?: JsonValue | undefined};
type JsonArray = JsonValue[] | readonly JsonValue[];
type JsonPrimitive = string | number | boolean | null;
export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

export const JsonSchema: z.ZodType<JsonValue> = z.lazy(() =>
    z.union([LiteralSchema, z.array(JsonSchema), z.record(JsonSchema)]),
);

export enum Currency {
    CZK = 'CZK',
    USD = 'USD',
    EUR = 'EUR',
    HUF = 'HUF',
}

export enum Language {
    cs = 'cs',
    en = 'en',
    sk = 'sk',
    de = 'de',
    hu = 'hu',
}

export enum Timezone {
    UTC = 'UTC',
    CET = 'CET',
    EuropePrague = 'Europe/Prague',
    EuropeBratislava = 'Europe/Bratislava',
    EuropeVienna = 'Europe/Vienna',
    EuropeBerlin = 'Europe/Berlin',
    EuropeBudapest = 'Europe/Budapest',
}

export enum CardBrand {
    VISA = 'VISA',
    MC = 'MC',
}

export const CountryToLanguage: Record<Country, Language> = {
    [Country.CZ]: Language.cs,
    [Country.SK]: Language.sk,
    [Country.DE]: Language.de,
    [Country.AT]: Language.de,
    [Country.HU]: Language.hu,
} satisfies Record<Country, Language>;

export const CountryToTimezone: Record<Country, Timezone> = {
    [Country.CZ]: Timezone.EuropePrague,
    [Country.SK]: Timezone.EuropeBratislava,
    [Country.DE]: Timezone.EuropeBerlin,
    [Country.AT]: Timezone.EuropeVienna,
    [Country.HU]: Timezone.EuropeBudapest,
} satisfies Record<Country, Timezone>;

export const CountryToCurrency: Record<Country, Currency> = {
    [Country.CZ]: Currency.CZK,
    [Country.SK]: Currency.EUR,
    [Country.DE]: Currency.EUR,
    [Country.AT]: Currency.EUR,
    [Country.HU]: Currency.HUF,
} satisfies Record<Country, Currency>;

export const CountryFromIbanSchema = IbanSchema.transform((value) => value.substr(0, 2)).pipe(z.nativeEnum(Country));

export interface PosError {
    message: string;
    code: string | null;
}

export interface ImageData {
    name: NonEmptyString;
    data: NonEmptyString;
}

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
    HEAD = 'HEAD',
}

export const BigNumberSchema: z.Schema<BigNumberType> = z.instanceof(BigNumber);
export const BigNumberFromStringOrNumberSchema: z.Schema<BigNumberType, z.ZodTypeDef, string | number> = z.union([DecimalStringSchema, z.number()]).transform((value) => new BigNumber(`${value}`)).pipe(z.instanceof(BigNumber));

/**
 * Percent is a number between 0 and 1
 * 0.5 = 50%
 * 0.1 = 10%
 */
export type PercentString = QerkoBrand<NonEmptyString, 'Percent'>;
export const PercentStringSchema: Schema<PercentString, string> = extendApi(createRegexSchema({ message: 'Value must be between 0 - 1 [0.15 = 15%, 0.5 = 50%]', regex: /^(1|0(\.[0-9]{1,2})?)$/ }), {
    type: ['string'],
    format: 'percent',
    pattern: '^(1|0(\\.[0-9]{1,2})?)$',
});

// export const PercentStringSchema = createRegexSchema({ message: 'Value must be between 0 - 1 [0.15 = 15%, 0.5 = 50%]', regex: /^(1|0(\.[0-9]{1,2})?)$/ })

export const ImageDataSchema: Schema<ImageData, unknown> = z.object({
    name: NonEmptyStringSchema,
    data: NonEmptyStringSchema,
}, { message: 'Value must be ImageData' });

export const StringSchema: Schema<string, unknown> = z.string({ message: 'Value must be string' });

export const NumberSchema: Schema<number, unknown> = z.number({ message: 'Value must be number' }).finite({ message: 'Value must be number' });

// eslint-disable-next-line no-restricted-syntax
export const ObjectSchema = z.record(z.string(), z.unknown());

/**
 * @deprecated do not use it ! POS portos has wrong implementation with milliseconds. We want to use it for quicker remove ajv validations
 *
 * transform:
 * "23:59:59.9999999" >> TimeStringSchema.parse("23:59:59")
 *
 * use TimeStringSchema or TimeStringMillisecondsSchema instead
 * */
export const TimeStringWithOptionalMillisecondsSchema = z.preprocess((x) => TimeStringSchema.parse(`${x}`.replace(/\.\d+$/g, '')), TimeStringSchema);

export function assertNotNull<T>(value: T): asserts value is (T extends null ? never : T) {
    if (value === null) {
        throw new TypeError('Value must not be null');
    }
}

export function assertNotUndefined<T>(value: T): asserts value is (T extends undefined ? never : T) {
    if (value === undefined) {
        throw new TypeError('Value must not be undefined');
    }
}

export function assertIsTrue(value: boolean): asserts value is true {
    if (value !== true) throw new TypeError('Value must be true');
}

export type OS = 'ios' | 'android';
export const CustomHelpDeskZodSchema = z.object({
    email: EmailSchema,
    name: NonEmptyString128Schema,
}).nullable();
export type CustomHelpDeskType = z.output<typeof CustomHelpDeskZodSchema>;

export interface StatisticsPayment {
    type: 'paymentPaid';
    user: {
        name: string;
    } | null;
    payment: {
        id: number;
        paymentGatewayId: string;
        currency: Currency;
        totalAmount: DecimalString;
        tip: DecimalString;
    };
    restaurant: {
        name: string;
        countryId: string;
        countryRegionId: Uuid;
    } | null;
    appVersion: {
        platform: string;
        build: string;
    } | null;
}

export const CommonErrorSchema = z.object({
    message: z.string(),
    reason: z.string().nullable(),
});

export interface Image {
    binary?: Blob;
    data?: NonEmptyString;
    id?: Uuid;
    name?: NonEmptyString;
    url?: NonEmptyString;
}
