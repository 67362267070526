import React, { useContext } from 'react';
import { z } from 'zod';
import { RestaurantPermissionSchema } from 'src/shared/restaurantTypes';
import { Currency, UuidSchema, PhoneSchema } from 'src/shared/types';

import type { Uuid } from 'src/shared/types';
import type { Restaurant } from '@mui/icons-material';

export const apiUrl = process.env.NEXT_PUBLIC_API_URL !== undefined
    ? process.env.NEXT_PUBLIC_API_URL
    : 'https://sandbox.qerko.com';

export const RestaurantCurrencies = [ Currency.CZK, Currency.EUR, Currency.HUF ] as const;

export type RestaurantPermissions = z.infer<typeof RestaurantPermissionSchema>;

export const RestaurantSchema = z.object({
    countryId: z.string(),
    currencies: z.nativeEnum(Currency).array(),
    defaultCurrency: z.nativeEnum(Currency),
    id: UuidSchema,
    identificationNumber: z.string(),
    isActive: z.boolean(),
    isAdyenOnboardingAllowed: z.boolean().default(false),
    isPayuOnboardingAllowed: z.boolean().default(false),
    isPilsnerEnabled: z.boolean(),
    isQerkoOnboardingAllowed: z.boolean().default(false),
    isTesting: z.boolean().nullable(),
    name: z.string(),
    permissions: RestaurantPermissionSchema,
    showPosDriverSettings: z.boolean(),
    showWaiterNameInPayments: z.boolean().nullable(),
    tipReductionForPersonel: z.number(),
    variableSymbol: z.string(),
});

// export type Restaurant = APIv1RestaurantAdmin['ENDPOINT']['GET:/restaurant']['result']['result'][0];

export interface Restaurant {
    id: Uuid;
    name: string;
    isTesting: boolean | null;
    isActive: boolean;
    showWaiterNameInPayments: boolean | null;
    permissions: RestaurantPermissions;
    tipReductionForPersonel: number;
    variableSymbol: string;
    identificationNumber: string;
    currencies: Currency[];
    defaultCurrency: Currency;
    isPilsnerEnabled: boolean;
    showPosDriverSettings: boolean;
    shiftConfig?: null | Record<string, unknown>;
    countryId: string;
    isAdyenOnboardingAllowed: boolean;
    isPayuOnboardingAllowed: boolean;
    isQerkoOnboardingAllowed: boolean;
}

const SuperadminSchema = z.object({
    ACT_AS_RESTAURANTS: z.boolean().optional(),
    CANCEL_PAYMENT: z.boolean().optional(),
    CONFIRM_PAYMENT: z.boolean().optional(),
    CREATE_RESTAURANTS: z.boolean().optional(),
    LIVE_CONFIG: z.boolean().optional(),
    PAYMENTS: z.boolean().optional(),
    PAYOUT: z.boolean().optional(),
    QRCODES: z.boolean().optional(),
    USERS: z.boolean().optional(),
});
export type SuperAdmin = z.output<typeof SuperadminSchema>;

export const UserSchema = z.object({
    email: z.string().nullable(),
    emailVerified: z.boolean(),
    id: z.string(),
    isManaged: z.boolean(),
    isPhoneVerified: z.boolean().default(false), // the default value can be removed after a few days
    name: z.string(),
    phone: PhoneSchema.nullable().default(null), // the default value can be removed after a few days
    superadmin: z.union([ z.literal(0), SuperadminSchema ]),
    surname: z.string(),
    username: z.string(),
});

export type User = z.output<typeof UserSchema>;

export const AuthSchema = z.object({
    accessToken: z.string(),
    user: UserSchema,
});

export type Auth = z.output<typeof AuthSchema>;

export interface AppContextData {
    auth: Auth | null;
    previousAuth: Auth | null;
    setAuth: (values: Auth | null) => void;
    apiUrl: string;
    isSandbox: boolean;
    setSandbox: (val: boolean) => void;
    logout: () => void;
}

export const AppContext = React.createContext<AppContextData>({
    apiUrl,
    auth: null,
    previousAuth: null,
    isSandbox: true,
    logout: () => undefined,
    setAuth: () => undefined,
    setSandbox: () => undefined,
});

export const AppContextProvider = (props: { value: AppContextData; children: React.ComponentElement<any, any> }) => {
    return (
        <AppContext.Provider value={props.value}>{props.children}</AppContext.Provider>
    );
};

export const useAppContext = (): AppContextData => {
    return useContext(AppContext);
};
