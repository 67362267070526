import {
    DateStringSchema,
    EmailSchema,
    NonEmptyStringSchema,
    NonEmptyString255Schema,
    PositiveIntegerSchema,
    UuidSchema,
} from 'src/shared/types';
import {
    ReservationSubSource,
} from 'src/shared-interface/ApiQrInterface';
import { z } from 'zod';

import type { OS } from 'src/shared/types';

export type SwitchState = 'reservation' | 'personalIdentification';
export type Environment = { isMobile: boolean; os: OS | null };

export const ReservationDataCreateSchema = z.object({
    date: DateStringSchema,
    note: NonEmptyString255Schema.nullable(),
    reservationSubSource: z.nativeEnum(ReservationSubSource).nullable().default(null),
    restaurantId: UuidSchema,
    seats: PositiveIntegerSchema,
    slotId: NonEmptyStringSchema,
    user: z.object({
        email: EmailSchema,
        name: NonEmptyString255Schema,
        phone: NonEmptyStringSchema,
    }),
});
