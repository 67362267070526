import React from 'react';
import Box from '@mui/material/Box';

export const ReservationBoxWhiteWrap = ({
    children,
}:
{
    children: React.ReactElement;
}) => (
    <Box sx={{
        backgroundColor: 'white',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        width: '407px',
    }}
    >
        {children}
    </Box>
);

