import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import type { UrlObject } from 'url';

export type RaRouterQuery = Record<string, string | string[] | undefined>;
export type RaRouterUrlObject = Omit<UrlObject, 'query'> & { query?: RaRouterQuery };

const createUseRouterWithPersistentQueries = <const T extends Record<string, true>>(persistentQueries: T) => () => {
    // eslint-disable-next-line no-restricted-syntax
    const router = useRouter();

    // Note - new callback on each render can cause re-renders if raRouter (return type of "useRaRouter") is used as dependency
    const createUrlObject = useCallback((args: RaRouterUrlObject) => ({
        ...args,
        query: {
            ...(Object.fromEntries(Object.keys(persistentQueries).map((key) => [key, router.query[key]]))),
            ...(args.query ?? {}),
        },
    }), [router.query]);

    const routerMemo = useMemo(() => ({
        ...router,
        createUrlObject,
        push: (args: RaRouterUrlObject) => router.push(createUrlObject(args)),
        replace: (args: RaRouterUrlObject) => router.replace(createUrlObject(args)),
    }), [router, createUrlObject]);
    return routerMemo;
};

export const useRaRouter = createUseRouterWithPersistentQueries({
    selectedRestaurantId: true,
});

export type RaRouter = ReturnType<typeof useRaRouter>;
