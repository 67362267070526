import { HttpUrlSchema } from './types';

import type { ValueOf } from 'type-fest';

export enum SupportedCurrenciesByRestaurants {
    CZK = 'CZK',
    EUR = 'EUR',
    HUF = 'HUF',
}

export enum OrderSubscriptionType {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
    Manual = 'manual',
}

export enum EshopOrderSource {
    ASWLINK = 'ASWLINK',
    ESHOP = 'ESHOP',
    EATNGO = 'EATNGO',
}

export enum EshopOrderState {
    OPEN = 'OPEN',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
}

export enum LoyaltyProgram {
    UNSET = 'UNSET',
    ALLOWED = 'ALLOWED',
    DENIED = 'DENIED',
    DEFERRED = 'DEFERRED',
}

export enum RestaurantTipHandling {
    NORMAL = 'NORMAL',
    NONE = 'NONE',
    INFO = 'INFO',
    NORMAL_WITHOUT_RECEIPT = 'NORMAL_WITHOUT_RECEIPT',
}

export enum RestaurantBusinessModel {
    Blend = 'blend',
    InterchangePlusPlus = 'iPlusPlus',
}

export enum RestaurantPayoutTipCalculationMethod {
    Exact = 'exact',
    Floor = 'floor',
    Ceil = 'ceil',
}

export enum IipClientRestaurantTipHandling {
    NORMAL = 'NORMAL',
    NONE = 'NONE',
    INFO = 'INFO',
    NORMAL_NO_DEFAULT = 'NORMAL_NO_DEFAULT',
}

export enum BusinessType {
    GASTRO = 'GASTRO',
    TV = 'TV',
    CHARGERS = 'CHARGERS',
    GAS_STATIONS = 'GAS_STATIONS',
}

/**
 * Namespaces for url ~qr/[namespace]/....
 * for example:
 *  - brand
 *      ~qr/ogn~
 *  - namespaces
 *      ~qr/table
 *      ~qr/eso
 *      ~qr/eshop-order
 */
export enum TableTagNamespace {
    TABLE = 'table',
    RESTAURANT = 'restaurant',
    RESTAURANT_ID = 'rid',
    ESHOP_ORDER = 'eso',
    POS = 'pos',
}

export const TableTagResolveMap: Record<string, TableTagNamespace> = {
    'restaurant-id': TableTagNamespace.RESTAURANT_ID,
    'eshop-order': TableTagNamespace.ESHOP_ORDER,
};

export enum TableTagMode {
    STATIC_TABLE = 'STATIC_TABLE',
    DIRECT_BILL = 'DIRECT_BILL',
}

export const MOBILE_STORES = {
    android: {
        /**
         * It redirects user into app or fallback url.
         * https://your_subdomain.page.link/?link=your_deep_link&apn=package_name[&amv=minimum_version][&afl=fallback_link]
         */
        native: 'https://qerko.page.link?apn=com.itispaid',
        nativeWithSchema: 'market://details?apn=com.itispaid',
        web: HttpUrlSchema.parse('https://play.google.com/store/apps/details?id=com.itispaid&launch=true'),
    },
    ios: {
        native: 'https://itunes.apple.com/app/id1363182258?pt=118990682',
        web: 'https://itunes.apple.com/app/id1363182258?pt=118990682',
    },
} as const;

export enum AllAvailableDrivers {
    AMADEUS = 'AMADEUS',
    QERKO = 'QERKO',
    SEPTIM = 'SEPTIM',
    SLACK = 'SLACK',
    RESTAURANT_ADMIN = 'RESTAURANT_ADMIN',
    DOTYKACKA = 'DOTYKACKA',
    QERKO_MENU = 'QERKO_MENU',
    HARSYS = 'HARSYS',
    OMNIVORE = 'OMNIVORE',
    SMARTPOS = 'SMARTPOS',
    ASSECO = 'ASSECO',
    PAPAYAPOS = 'PAPAYAPOS',
    STORYOUS = 'STORYOUS',
    STORYOUS_V2 = 'STORYOUS_V2',
    MOCK = 'MOCK',
}

export const markAsPaidDrivers = {
    AMADEUS: AllAvailableDrivers.AMADEUS,
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    SLACK: AllAvailableDrivers.SLACK,
    RESTAURANT_ADMIN: AllAvailableDrivers.RESTAURANT_ADMIN,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    HARSYS: AllAvailableDrivers.HARSYS,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    ASSECO: AllAvailableDrivers.ASSECO,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type MarkAsPaidDrivers = ValueOf<typeof markAsPaidDrivers>;
export const orderableDrivers = {
    AMADEUS: AllAvailableDrivers.AMADEUS,
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    SLACK: AllAvailableDrivers.SLACK,
    RESTAURANT_ADMIN: AllAvailableDrivers.RESTAURANT_ADMIN,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    HARSYS: AllAvailableDrivers.HARSYS,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    ASSECO: AllAvailableDrivers.ASSECO,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type OrderableDrivers = ValueOf<typeof orderableDrivers>;
export const byOperatorOrderableDrivers = {
    QERKO: AllAvailableDrivers.QERKO,
} as const;
export type ByOperatorOrderableDrivers = ValueOf<typeof byOperatorOrderableDrivers>;
export const byCustomerOrderableDrivers = {
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    SLACK: AllAvailableDrivers.SLACK,
    RESTAURANT_ADMIN: AllAvailableDrivers.RESTAURANT_ADMIN,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    HARSYS: AllAvailableDrivers.HARSYS,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    ASSECO: AllAvailableDrivers.ASSECO,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type ByCustomerOrderableDrivers = ValueOf<typeof byCustomerOrderableDrivers>;
export const toBillOrderableDrivers = {
    AMADEUS: AllAvailableDrivers.AMADEUS,
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    SLACK: AllAvailableDrivers.SLACK,
    HARSYS: AllAvailableDrivers.HARSYS,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    ASSECO: AllAvailableDrivers.ASSECO,
} as const;
export type ToBillOrderableDrivers = ValueOf<typeof toBillOrderableDrivers>;
export const toTableOrderableDrivers = {
    AMADEUS: AllAvailableDrivers.AMADEUS,
    QERKO: AllAvailableDrivers.QERKO,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    SLACK: AllAvailableDrivers.SLACK,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    HARSYS: AllAvailableDrivers.HARSYS,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    ASSECO: AllAvailableDrivers.ASSECO,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type ToTableOrderableDrivers = ValueOf<typeof toTableOrderableDrivers>;
export const tableServiceDrivers = {
    QERKO: AllAvailableDrivers.QERKO,
    AMADEUS: AllAvailableDrivers.AMADEUS,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    SLACK: AllAvailableDrivers.SLACK,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    PAPAYAPOS: AllAvailableDrivers.PAPAYAPOS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
    MOCK: AllAvailableDrivers.MOCK,
} as const;
export type TableServiceDrivers = ValueOf<typeof tableServiceDrivers>;
export const menuDrivers = {
    AMADEUS: AllAvailableDrivers.AMADEUS,
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    QERKO_MENU: AllAvailableDrivers.QERKO_MENU,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    ASSECO: AllAvailableDrivers.ASSECO,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type MenuDrivers = ValueOf<typeof menuDrivers>;
export const menuDriversWithImageSupport = {
    QERKO: AllAvailableDrivers.QERKO,
    SEPTIM: AllAvailableDrivers.SEPTIM,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    SMARTPOS: AllAvailableDrivers.SMARTPOS,
    STORYOUS: AllAvailableDrivers.STORYOUS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
} as const;
export type MenuDriversWithImageSupport = ValueOf<typeof menuDriversWithImageSupport>;
export const payableDrivers = {
    QERKO: AllAvailableDrivers.QERKO,
    DOTYKACKA: AllAvailableDrivers.DOTYKACKA,
    OMNIVORE: AllAvailableDrivers.OMNIVORE,
    AMADEUS: AllAvailableDrivers.AMADEUS,
    PAPAYAPOS: AllAvailableDrivers.PAPAYAPOS,
    STORYOUS_V2: AllAvailableDrivers.STORYOUS_V2,
    MOCK: AllAvailableDrivers.MOCK,
} as const;
export type PayableDrivers = ValueOf<typeof payableDrivers>;
export const bookableDrivers = {
    RESTAURANT_ADMIN: AllAvailableDrivers.RESTAURANT_ADMIN,
} as const;
export type BookableDrivers = ValueOf<typeof bookableDrivers>;

// @deprecated use only for private stats it is not exact
export const EurCzk = 24.4;

export enum PrintType {
    Email = 'email',
    None = 'none',
    Local = 'local',
}

export enum AmaduesReceiptDeliveryType {
    DigitalByEmail = 'digitalByEmail', // must be set in Amadeus settings, qerko send email to customer with receipt
    EmployeeHasToManuallyPrint = 'employeeHasToManuallyPrint', // employee will print receipt manually
    Paper = 'paper', // printer in restaurant will be print receipt
}

export enum PayoutReportTerm {
    Daily = 'daily',
    Monthly = 'monthly',
}

export enum PayoutReportType {
    Csv = 'csv',
    Pdf = 'pdf',
    Html = 'html',
}

export enum Mimetype {
    Csv = 'text/csv',
    Pdf = 'application/pdf',
    Html = 'text/html',
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Webp = 'image/webp',
}
