import { isEmpty } from 'lodash';
import { parse, format } from 'date-fns';
import { NonEmptyStringSchema, PositiveIntegerSchema } from 'src/shared/types';

import type { ReservationDataCreateSchema } from './types';
import type { DateTimeString, TimeString } from 'src/shared/types';
import type { z } from 'zod';
import type { FormikType } from 'src/form-utils';

export const isButtonDisabledBoxStep1 = (formik: FormikType<z.infer<typeof ReservationDataCreateSchema>>) => {
    return (
        formik.errors.slotId !== undefined ||
        isEmpty(formik.values.date) ||
		!PositiveIntegerSchema.safeParse(formik.values.seats).success ||
		!NonEmptyStringSchema.safeParse(formik.values.slotId).success
    );
};

export const parseTimeStringToHoursAndMinutes = (time: TimeString) => format(parse(time, 'HH:mm:ss', new Date()), 'HH:mm');
export const parseSlotIdToDate = (time: DateTimeString) => format(parse(time, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd. MM. yyyy HH:mm');
