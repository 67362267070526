import SvgIcon from '@mui/material/SvgIcon';

import type { SvgIconProps } from '@mui/material';

export const QerkoLogoIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 378.7 114.6">
        <svg viewBox="0 0 378.7 114.6" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
            <style type="text/css">
                {'.st0{fill:#00B579;}'}
            </style>
            <g>
                <path className="st0" d="M164.5062256,63.1098175H119.506218c1.8000031,6.3000031,7.1999969,10,15.2000046,10
		c6.1000061,0,10.1000061-1.6999969,14.3000031-5.3999939l11.3999939,11.8999939
		c-5.8999939,6.6000061-14.6000061,10.1000061-26.3999939,10.1000061c-22.0000076,0-36.3000107-13.6000061-36.3000107-32.1999969
		c0-18.6999969,14.5-32,34.0000076-32c18.3000031,0,33.0999908,11.6999989,33.0999908,32.1999969
		C164.8062134,59.3098145,164.6062164,61.4098206,164.5062256,63.1098175z M119.2062149,51.6098175h25.4000015
		c-1.1000061-6.5-5.8999939-10.6999969-12.6999969-10.6999969C125.2062149,41.0098114,120.506218,45.1098175,119.2062149,51.6098175
		z"
                />
                <path className="st0" d="M214.1062317,25.5098095v19.600008c-1.8999939-0.1999969-3.2999878-0.3000031-4.8999939-0.3000031
		c-8.7999878,0-14.7999878,4.5-14.7999878,15.3000031v28.5h-21.7000122V26.5098171h20.6999817v7.4000034
		C197.8062134,28.409811,205.0062256,25.5098095,214.1062317,25.5098095z"
                />
                <path className="st0" d="M248.1062317,66.8098145l-6.3999939,6.5v15.1999969h-21.7000122v-85h21.7000122v44.5999908
		L263.90625,26.5098019h25.7000122L263.90625,53.1098022l27.8999939,35.5h-26.4000244L248.1062317,66.8098145z"
                />
                <path className="st0" d="M284.3062134,57.5098114c0-18.6999969,14.7000122-32,35-32c20.3999939,0,35,13.300005,35,32
		c0,18.8000031-14.5,32.1999969-35,32.1999969C299.0062256,89.6098175,284.3062134,76.2098083,284.3062134,57.5098114z
		 M332.2062073,57.5098114c0-9.3999939-5.6000061-14.6999969-13-14.6999969c-7.2999878,0-13,5.3000031-13,14.6999969
		c0,9.5,5.7000122,14.8999939,13,14.8999939C326.6062317,72.3098145,332.2062073,67.0098114,332.2062073,57.5098114z"
                />
                <path className="st0" d="M94.506218,107.0098114l-8.1000061-6.8999939c-0.1999969-0.1999969-0.3000031-0.5-0.1999969-0.8000031
		l4.1999969-9.5c0.1999969-0.5-0.1999969-1-0.6999969-0.8999939c-9.6999969,2-17.3999939,0.8999939-23.3000031-2
		c-7.1999931-3.5-11.9999962-9.5-15.2999992-15.6999969c-4-7.5-5.8000031-15.1999969-6.8000031-18.8000031
		c-0.1999969-0.6000061-1.1000061-0.6999969-1.3000031-0.1000061c-2.3000031,5.1999969-3.3000031,10.6999969-3.3000031,16.5
		c0,0.3000031,0,0.6000061,0,0.8000031c-2.1999969-0.8999939-4.0999985-2.1999969-5.8000031-3.6999969
		c-4.4000015-4-7.0999985-10-7.0999985-17.5c0-13.8999939,9.5-22.9000034,21.5999985-22.9000034
		s21.6000023,9.0000095,21.6000023,22.9000034c0,9.8000031-4.6999969,17.1000061-11.6999931,20.6000061
		c-0.6000061,0.3000031-0.8000031,1-0.5,1.6000061c0.8000031,1.3000031,1.6999969,2.6000061,2.6999969,3.8000031
		c3.6999931,4.5,8.1000023,7.3999939,13.3999901,8.6999969c11.6999969-7.3000031,18.8999939-19.8000031,18.8999939-34.6999969
		c0-24.0000019-18.8000031-41.6999969-44.4000053-41.6999969S3.9062152,24.3098125,3.9062152,48.4098206
		c0,20.8000031,14.0999985,36.8000031,34.3999939,40.6999969c2.1999969,0.3999939,4.5,0.6999969,6.8999939,0.8999939
		c0.3000031,0,0.6000061,0.1999969,0.8000031,0.3999939c2.8000031,4.3000031,6.3999939,8.1000061,10.8000031,11.1000061
		c10.7999992,7.8000031,24.9999962,9.8000031,37.6999931,6.6000061
		C94.6062088,108.1098175,94.8062057,107.4098206,94.506218,107.0098114z"
                />
                <g>
                    <path className="st0" d="M349.9062195,34.3098145c-0.8999939,0-1.7999878-0.1999969-2.5-0.6000061
			c-0.7000122-0.3999939-1.2999878-1-1.7000122-1.6999969c-0.3999939-0.6999989-0.6000061-1.600008-0.6000061-2.5000019
			c0-0.9000015,0.2000122-1.8000031,0.6000061-2.5s1-1.3000031,1.7000122-1.6999969
			c0.7000122-0.4000015,1.6000061-0.5999985,2.5-0.5999985s1.7999878,0.1999969,2.5,0.5999985s1.2999878,1,1.7000122,1.6999969
			c0.3999939,0.6999969,0.6000061,1.5999985,0.6000061,2.5c0,0.8999939-0.2000122,1.8000031-0.6000061,2.5000019
			c-0.3999939,0.6999969-1,1.3000031-1.7000122,1.6999969C351.6062317,34.1098175,350.8062134,34.3098145,349.9062195,34.3098145z
			 M349.9062195,33.4098206c1.2000122,0,2.1000061-0.3999939,2.7999878-1.1000061
			c0.7000122-0.6999989,1.1000061-1.6999989,1.1000061-2.800005c0-1.1999969-0.3999939-2.0999985-1.1000061-2.8000031
			c-0.7000122-0.6999969-1.7000122-1.0999985-2.7999878-1.0999985c-1.2000122,0-2.1000061,0.4000015-2.7999878,1.0999985
			c-0.7000122,0.6999969-1.1000061,1.6999969-1.1000061,2.8000031c0,1.1999969,0.3999939,2.1000061,1.1000061,2.800005
			C347.8062134,33.0098114,348.6062317,33.4098206,349.9062195,33.4098206z M348.2062073,32.0098114v-5.1999989h2
			c0.6000061,0,1,0.0999985,1.2999878,0.4000015s0.3999939,0.5999985,0.3999939,1
			c0,0.3000031-0.1000061,0.5999985-0.2000122,0.8000031c-0.1000061,0.1999969-0.3999939,0.4000015-0.7000122,0.5v0.1999969
			c0.2999878,0,0.5,0.0999985,0.6000061,0.3000031c0.1000061,0.1999969,0.2000122,0.4000015,0.2000122,0.5999985v1.3999958h-1
			v-1.3999958c0-0.3000031-0.2000122-0.5-0.5-0.5h-1.2000122v1.8999958H348.2062073z M349.2062073,29.3098125h1.1000061
			c0.2999878,0,0.5-0.0999985,0.6000061-0.1999969c0.1000061-0.1999969,0.2000122-0.3000031,0.2000122-0.5
			s-0.1000061-0.4000015-0.2000122-0.5s-0.2999878-0.1999969-0.6000061-0.1999969h-1.1000061V29.3098125z"
                    />
                </g>
            </g>
        </svg>
    </SvgIcon>
);
