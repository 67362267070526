import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RedCrossIcon } from 'common/components/icons/RedCross';

import { ReservationBoxWhiteWrap } from './ReservationBoxWhiteWrap';
import { ReservationBoxFooter } from './ReservationBoxFooter';

export const ReservationBoxIsNotAllowed = () => {
    const { t } = useTranslation('restaurant.reservation');

    return (
        <ReservationBoxWhiteWrap>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'space-between', minHeight: '20rem' }}>
                <Box sx={{ m: '40px' }}>
                    <Box sx={{ alignItems: 'center', backgroundColor: '#FEDBE1', borderRadius: '9px', display: 'flex', height: '72px', justifyContent: 'center', mb: '24px', width: '72px' }}>
                        <RedCrossIcon />
                    </Box>
                    <Typography variant="h1">{t('restaurantHasTurnedOffReservationByQerko')}</Typography>
                </Box>
                <ReservationBoxFooter includeSeznam={false} />
            </Box>
        </ReservationBoxWhiteWrap>
    );
};
