import Box from '@mui/material/Box';
import * as React from 'react';
import { ReservationBoxIsNotAllowed } from 'restaurant/modules/reservation/ReservationBoxIsNotAllowed';
import { ReservationQueryWrap } from 'restaurant/modules/reservation/ReservationQueryWrap';
import { ReservationBoxDetail } from 'restaurant/modules/reservation/ReservationBoxDetail';
import { RestaurantTakeawayBox } from 'restaurant/modules/restaurant-menu/RestaurantTakeawayBox';
import { RestaurantMenuBox } from 'restaurant/modules/restaurant-menu/RestaurantMenuBox';
import { RestaurantMenuDailyBox } from 'restaurant/modules/restaurant-menu/RestaurantMenuDailyBox';
import { FullScreenLoader } from 'common/components/FullScreenLoader';
import { useQrRequest } from 'src/apiClient';

import { RestaurantLayout } from './RestaurantLayout';

import type { APIv1Qr } from 'src/shared-interface/ApiQrInterface';

export const RestaurantMain = ({
    page,
    reservation,
    restaurantId,
}: {
    page: 'create' | 'detail' | 'menu' | 'dailyMenu' | 'takeAway';
    reservation?: APIv1Qr['ENDPOINT']['GET:/reservation/retrieve']['result']['result']['reservation'];
    restaurantId: string;
}) => {
    const queryKey = ['/restaurant/by-id/:restaurantId', restaurantId];
    const { isInitialLoading: isLoading, data: responseRestaurant } = useQrRequest('GET:/restaurant/by-id/:restaurantId', queryKey, {
        params: { restaurantId },
    });

    if (isLoading) return <FullScreenLoader componentOrShow={false} />;
    if (responseRestaurant?.httpStatus !== 200) return <Box>Restaurant was not found</Box>;

    const restaurant = responseRestaurant?.result.restaurant;
    const isReservationAllowed = !!restaurant?.isReservationAllowed;

    if (!restaurant) return <FullScreenLoader componentOrShow={false} />;

    return (
        <RestaurantLayout restaurant={restaurant}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {page === 'create' && isReservationAllowed && (<ReservationQueryWrap restaurant={restaurant} />)}
                {page === 'create' && !isReservationAllowed && (<ReservationBoxIsNotAllowed />)}
                {page === 'detail' && reservation && isReservationAllowed && (
                    <ReservationBoxDetail reservation={reservation} />
                )}
                {page === 'menu' && (<RestaurantMenuBox restaurant={restaurant} />)}
                {page === 'dailyMenu' && (<RestaurantMenuDailyBox restaurant={restaurant} />)}
                {page === 'takeAway' && (<RestaurantTakeawayBox restaurant={restaurant} />)}
            </Box>
        </RestaurantLayout>
    );
};
