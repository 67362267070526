import BigNumber from 'bignumber.js';

import type { BigNumber as BigNumberType } from 'bignumber.js';

export function bigNumber(num: string | number): BigNumberType {
    return new BigNumber(num);
}

export function toDecimalPlaces(num: string | number | BigNumberType, decimalPlaces = 2): string {
    if (num instanceof BigNumber) return num.decimalPlaces(decimalPlaces).toString();
    return bigNumber(num).decimalPlaces(decimalPlaces).toString();
}

export function toFixedDecimalPlaces(num: string | number | BigNumberType, decimalPlaces = 2): string {
    if (num instanceof BigNumber) return num.decimalPlaces(decimalPlaces).toString();
    return bigNumber(num).decimalPlaces(decimalPlaces).toNumber().toFixed(decimalPlaces);
}

/**
 * @deprecated use instead of formatAmountWithCurrency
 */
export function toPriceFormat(num: string | number | BigNumberType, decimalPlaces = 2) {
    return ((num instanceof BigNumber) ? num : bigNumber(num)).decimalPlaces(decimalPlaces).toNumber().toFixed(decimalPlaces).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ').replace('.', ',');
}
